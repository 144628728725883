import { createQuery } from '@farfetched/core';
import { msg } from '@lingui/macro';
import { AxiosError } from 'axios';
import { attach, combine, createEffect, createEvent, createStore, sample } from 'effector';
import { not } from 'patronum';

import { deleteAccountFx } from '@entities/profile';

import { MeDeleteRequest } from '@shared/api/id-frontend-api';
import { createForm } from '@shared/lib/effector-form';
import { localeService } from '@shared/services/locale';
import { sanitizeAppLanguageSetting } from '@shared/services/locale/lib/helpers';
import { modalService } from '@shared/services/modals';
import { notificationModel } from '@shared/services/notification';

import { deleteAccountFormModal } from './config';

const $serverError = createStore('');
const $$deleteAccountForm = createForm({ fields: deleteAccountFormModal });

const deleteAccountFxMutation = createQuery({
  effect: attach({
    source: {
      lang: localeService.outputs.$appLanguage,
    },
    mapParams: (params: MeDeleteRequest, { lang }) => ({
      ...params,
      lang: sanitizeAppLanguageSetting(lang),
    }),
    effect: deleteAccountFx,
  }),
});

const reloadPageFx = createEffect(() => {
  window?.location.reload();
});

const $currentModal = modalService.outputs.$activeModals.map(
  (activeModals) => activeModals.find((i) => i.name === 'delete-account') ?? null,
);

const start = createEvent();

const { translated } = localeService.outputs;
const modalOpened = sample({ source: $currentModal, filter: Boolean });
const modalClosed = createEvent();
const completed = createEvent();
const addError = createEvent<string>();
const finished = {
  success: createEvent(),
};

const $serverErrorMsg = translated(msg`Произошла ошибка.`);

$serverError.on(addError, (_, msg) => msg).on([modalClosed, modalOpened], () => '');

sample({
  clock: $$deleteAccountForm.submit,
  source: $$deleteAccountForm.$values,
  filter: ({ isSocialAuth }) => !isSocialAuth,
  fn: ({ password }) => {
    return {
      password,
    };
  },
  target: deleteAccountFxMutation.start,
});

sample({
  clock: deleteAccountFxMutation.finished.success,
  target: reloadPageFx,
});

sample({
  clock: deleteAccountFxMutation.finished.failure,
  source: { errorMsg: $serverErrorMsg },
  fn: ({ errorMsg }, { error }) => {
    if (error instanceof AxiosError && error.response) {
      return { message: error.response.data.msg as string };
    }

    return { message: errorMsg };
  },
  target: notificationModel.inputs.showErrorFx,
});

const $isSubmitDisabled = combine(
  [
    not($$deleteAccountForm.$isDirty),
    not($$deleteAccountForm.$isValid),
    deleteAccountFxMutation.$pending,
  ],
  (sources) => sources.some(Boolean),
);

sample({
  clock: [modalClosed, finished.success],
  target: $$deleteAccountForm.reset,
});

export {
  $$deleteAccountForm,
  modalClosed,
  completed,
  $serverError,
  addError,
  start,
  finished,
  $isSubmitDisabled,
};
