import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  GetShapeValue,
  sample,
  StoreShape,
} from 'effector';

import { createBarrier } from '@shared/lib/barrier';
import { Nullable } from '@shared/types';

export const fillUserToken = createEvent<string | null>();
export const fillChannelToken = createEvent<string | null>();

const $userToken = createStore<Nullable<string>>(null).on(fillUserToken, (_, token) => token);
const $channelToken = createStore<Nullable<string>>(null).on(fillChannelToken, (_, token) => token);

export const $withUserToken = combine($userToken, (token) => ({
  Authorization: `Bearer ${token}`,
}));

export const $withChannelToken = combine($channelToken, (token) => ({
  Authorization: `Bearer ${token}`,
}));

type Sources<T> = T extends StoreShape ? T : null;

export const createRequestEffect = <
  RequestParam,
  ResponseResult,
  States,
  StatesValue extends GetShapeValue<Sources<States>>,
>(config: {
  handler: (params: RequestParam, source: StatesValue) => Promise<ResponseResult>;
  source?: Sources<States>;
  barriers?: ReturnType<typeof createBarrier>[];
}) => {
  const apiRequestFx = config.source
    ? attach({
        source: config.source,
        mapParams: (params: RequestParam, source) => {
          return { source, params };
        },
        effect: createEffect(({ params, source }: { params: RequestParam; source: StatesValue }) =>
          config.handler(params, source),
        ),
      })
    : createEffect((params: RequestParam) => config.handler(params, null as StatesValue));

  if (config.barriers && config.barriers.length > 0) {
    sample({
      clock: apiRequestFx.fail,
      target: config.barriers.map((i) => i.__.requestFailed),
    });
  }

  return attach({
    source: (config.barriers || []).map((i) => i.__.$mutex),
    effect: async (barriers, params: RequestParam) => {
      for (const barrier of barriers) {
        if (barrier?.isLocked) {
          await barrier?.waitForUnlock();
        }
      }

      return apiRequestFx(params).catch(async (cause) => {
        for (const barrier of barriers) {
          if (barrier?.isLocked) {
            await barrier?.waitForUnlock();
            return apiRequestFx(params);
          }
        }

        console.error('API error:', cause);
        throw cause;
      });
    },
  });
};
