import { i18n } from '@lingui/core';
import { useUnit } from 'effector-solid';
import { type Component, createMemo } from 'solid-js';
import { Dynamic } from 'solid-js/web';

import { I18nProvider } from '@shared/lib/lingui-solid';
import { localeService } from '@shared/services/locale';

export const withI18n = (WrappedComponent: Component) => (props) => {
  const isReady = useUnit(localeService.outputs.isReady);

  const view = createMemo(() => {
    if (isReady()) {
      return () => (
        <I18nProvider i18n={i18n}>
          <WrappedComponent {...props} />
        </I18nProvider>
      );
    }

    return () => null;
  });

  return <Dynamic component={view()} />;
};
