import { Atom } from '@solid-kit/aria';
import { JSX } from 'solid-js';

import { Table } from './table';
import { TableBody } from './table-body';
import { TableHead } from './table-head';

type CustomRenderValue = JSX.Element | string | number | null;

type HeaderRenderFunction = () => CustomRenderValue;
type ColumnRenderFunction<T> = (
  row: T,
  params?: {
    rowIsHovered?: Atom<boolean>;
  },
) => CustomRenderValue;

// -- Ожидаемый интрефейс для строки
export type RowData = Record<string, any> & {
  id: string | number;
};

// -- Настройка колонок
export type ColumnOption<T> = {
  title?: string;
  key?: keyof T & string;
  renderHeader?: HeaderRenderFunction;
  renderContent?: ColumnRenderFunction<T>;
  width?: `${number}rem` | 'min-content';
  minWidth?: `${number}rem`;
  flow?: number;
  align?: 'left' | 'center' | 'right';
  fixed?: boolean;
};

type TableGeneralProps<T extends RowData> = {
  columnOptions: ColumnOption<T>[];
  rows: T[];
};

export function TableGeneral<T extends RowData>(props: TableGeneralProps<T>) {
  return (
    <Table>
      <TableHead columnOptions={props.columnOptions} />
      <TableBody columnOptions={props.columnOptions} rows={props.rows} />
      {/* Тут будет находится lazyTrigger */}
    </Table>
  );
}
