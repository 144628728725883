import { combine, createEvent, createStore, sample } from 'effector';

import { breakpointsModel } from '@shared/services/breakpoints';

import { LayoutType } from '../consts';

const start = createEvent();
const sidebarOpened = createEvent();
const sidebarClosed = createEvent();
const setLayoutType = createEvent<LayoutType>();

const $padding = createStore(1);
const $layoutType = createStore<LayoutType>(LayoutType.Base);
const $sidebarIsOpened = createStore<boolean>(false);
const $sidebarIsCollapsed = combine(
  breakpointsModel.outputs.breakpoints.md.$matches,
  $sidebarIsOpened,
  (is_md, sidebarIsOpened) => !(is_md && sidebarIsOpened),
);

$sidebarIsOpened.on(sidebarOpened, () => true).on(sidebarClosed, () => false);
$layoutType.on(setLayoutType, (_, type) => type);

sample({
  clock: [start, breakpointsModel.outputs.breakpoints.md.$matches],
  source: breakpointsModel.outputs.breakpoints.md.$matches,
  target: $sidebarIsOpened,
});

sample({
  clock: [
    start,
    breakpointsModel.outputs.breakpoints.xl.$matches,
    breakpointsModel.outputs.breakpoints.md.$matches,
    breakpointsModel.outputs.breakpoints.base.$matches,
  ],
  source: [
    breakpointsModel.outputs.breakpoints.xl.$matches,
    breakpointsModel.outputs.breakpoints.md.$matches,
    breakpointsModel.outputs.breakpoints.base.$matches,
  ],
  fn: ([lg, md, base]) => {
    switch (true) {
      case lg:
        return 3;
      case md:
        return 2;
      case base:
      default:
        return 1;
    }
  },
  target: $padding,
});

export {
  $layoutType,
  sidebarOpened,
  sidebarClosed,
  $sidebarIsOpened,
  $sidebarIsCollapsed,
  setLayoutType,
  $padding,
  start,
};
