import { msg, Trans } from '@lingui/macro';
import { Stack } from '@solid-kit/ui';
import { Text } from '@solid-kit/ui/v2';
import { useUnit } from 'effector-solid';
import { Show } from 'solid-js';

import { useForm } from '@shared/lib/effector-form';
import { useLingui } from '@shared/lib/lingui-solid';
import { styleUtils } from '@shared/ui/styles/utils.css';
import { ValidationNumbers } from '@shared/ui/validation-numbers';

import { $$timer, $serverError, codeVerifyForm, resendCodeMutation } from '../../model';

export const CodeVerifyField = () => {
  const form = useForm(codeVerifyForm);
  const mutation = useUnit(resendCodeMutation);

  const { _ } = useLingui();

  const { time, serverError } = useUnit({
    serverError: $serverError,
    time: $$timer.$time,
  });

  const handleSubmit = () => {
    form.submit();
  };

  const handleResendCode = () => {
    mutation.start();
  };

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="column" spacing={0.5} alignitems="center">
        <ValidationNumbers
          title={_(msg`Код из SMS`)}
          pinInputFailed={form.hasError() || serverError()}
          onValueChange={({ valueAsString }) => {
            form.fields.codeValue.onChange(valueAsString);
          }}
          onValueComplete={(clear) => {
            handleSubmit();
            setTimeout(() => {
              if (form.fields.codeValue.hasError() || serverError()) {
                clear();
              }
            }, 300);
          }}
        />
        <Show when={form.fields.codeValue.hasError() || serverError()}>
          <Text class={styleUtils.color('red')} variant="body-1">
            {form.fields.codeValue.hasError() || serverError()}
          </Text>
        </Show>
      </Stack>

      <Show
        when={!time()}
        fallback={
          <Text variant="body-2" class={styleUtils.text_align('center')}>
            <Trans>Код отправлен. Время действия кода {time()} сек</Trans>
          </Text>
        }
      >
        <Stack alignitems="center" direction="column" spacing={0}>
          <Text as="p" variant="body-2">
            <Trans>Время действия кода истекло.</Trans>
          </Text>
          <Text as="p" variant="body-2">
            <a class={styleUtils.color('red')} onClick={handleResendCode}>
              <Trans>Отправить новый</Trans>
            </a>
          </Text>
        </Stack>
      </Show>
    </Stack>
  );
};
