import { attach, createStore } from 'effector';
import { toast as solidToast } from 'solid-toast';

import { createNotification } from '../ui';

const $notificationApi = createStore(solidToast);

const showSuccessFx = attach({
  source: $notificationApi,
  effect(api, { message }: { message: string }) {
    if (!api) {
      throw new Error('Notification API is not ready');
    }
    api.custom((toast) => createNotification({ message, toast }));
  },
});

const showErrorFx = attach({
  source: $notificationApi,
  effect(api, { message }: { message: string }) {
    if (!api) {
      throw new Error('Notification API is not ready');
    }
    api.custom((toast) => createNotification({ message, toast }));
  },
});

export { showSuccessFx, showErrorFx };
