import type { Branch, Route, RouteDefinition, RouteMatch } from '../types';
import { joinPaths } from './path';
import { createMatcher, expandOptionals, scoreRoute } from './utils';

function asArray<T>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : [value];
}

export function getRouteMatches(branches: Branch[], location: string): RouteMatch[] {
  for (let i = 0, len = branches.length; i < len; i++) {
    const match = branches[i].matcher(location);
    if (match) {
      return match;
    }
  }
  return [];
}

export function createRoutes(routeDef: RouteDefinition, base: string = ''): Route[] {
  const { children, route } = routeDef;
  const isLeaf = !children || (Array.isArray(children) && !children.length);

  return asArray(routeDef.path).reduce<Route[]>((acc, path) => {
    for (const originalPath of expandOptionals(path)) {
      const path = joinPaths(base, originalPath);

      let pattern = isLeaf ? path : path.split('/*', 1)[0];
      pattern = pattern
        .split('/')
        .map((s: string) => {
          return s.startsWith(':') || s.startsWith('*') ? s : encodeURIComponent(s);
        })
        .join('/');

      acc.push({
        route,
        originalPath,
        pattern,
        matcher: createMatcher(pattern, !isLeaf, routeDef.matchFilters),
      });
    }

    return acc;
  }, []);
}

export function createBranch(routes: Route[], index: number = 0): Branch {
  return {
    routes,
    score: scoreRoute(routes[routes.length - 1]) * 10000 - index,
    matcher(location) {
      const matches: RouteMatch[] = [];

      for (let i = routes.length - 1; i >= 0; i--) {
        const route = routes[i];
        const match = route.matcher(location);
        if (!match) {
          return null;
        }
        matches.unshift({
          ...match,
          route,
        });
      }
      return matches;
    },
  };
}

export function createBranches(
  routeDef: RouteDefinition | RouteDefinition[],
  base: string = '',
  stack: Route[] = [],
  branches: Branch[] = [],
): Branch[] {
  const routeDefs = asArray(routeDef);

  for (let i = 0, len = routeDefs.length; i < len; i++) {
    const def = routeDefs[i];
    if (def && typeof def === 'object') {
      if (!def.hasOwnProperty('path')) def.path = '';
      const routes = createRoutes(def, base);
      for (const route of routes) {
        stack.push(route);
        const isEmptyArray = Array.isArray(def.children) && def.children.length === 0;
        if (def.children && !isEmptyArray) {
          createBranches(def.children, route.pattern, stack, branches);
        } else {
          const branch = createBranch([...stack], branches.length);
          branches.push(branch);
        }

        stack.pop();
      }
    }
  }

  // Stack will be empty on final return
  return stack.length ? branches : branches.sort((a, b) => b.score - a.score);
}
