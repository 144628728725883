import { styled } from '@solidex/linaria';

export const Root = styled.div<{
  layoutIsCollapsed?: boolean;
  withRightBar?: boolean;
}>`
  --left: calc(-1 * var(--layout-root-padding));

  position: relative;
  min-height: 100%;
  margin-right: ${({ withRightBar }) => (withRightBar ? 'var(--sider-width)' : 0)};
  margin-left: ${({ layoutIsCollapsed }) => (layoutIsCollapsed ? 0 : 'var(--left)')};
  padding-right: var(--layout-root-padding);
  padding-left: var(--layout-root-padding);
  transition:
    padding 0.15s linear,
    margin 0.15s linear;
`;
