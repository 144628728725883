import { registerPlatformService } from '@shared/lib/composer';

import { APP_LANGUAGES } from './languages';
import { sanitizeAppLanguageSetting } from './lib/helpers';
import { localeModel } from './model';

export const localeService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: localeModel.inputs.start,
    stop: localeModel.inputs.stop,
  },
  api: {
    inputs: {
      ...localeModel.inputs,
    },
    outputs: {
      ...localeModel.outputs,
    },
    utils: {
      sanitizeAppLanguageSetting,
    },
    consts: {
      APP_LANGUAGES,
    },
  },
});
