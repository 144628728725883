import { attach, createEffect, createEvent, createStore, sample, split } from 'effector';

import {
  type EmptyObject,
  Kind,
  type NavigateParams,
  type RouteInstance,
  type RouteParams,
  type RouteParamsAndQuery,
  type RouteQuery,
} from '../types';

export function createRoute<Params extends RouteParams = {}>(): RouteInstance<Params> {
  const navigateFx = createEffect<NavigateParams<Params>, NavigateParams<Params>>(
    ({ params, query, replace = false }) => {
      return {
        params: params || {},
        query: query || {},
        replace,
      };
    },
  );

  const openFx = attach({
    effect: navigateFx,
    mapParams: (params: Params extends EmptyObject ? void : Params) => ({
      params: (params || {}) as Params,
      query: {} as Params,
    }),
  });

  const $isOpened = createStore(false);
  const $params = createStore<Params>({} as Params);
  const $query = createStore<RouteQuery>({});

  const opened = createEvent<RouteParamsAndQuery<Params>>();
  const updated = createEvent<RouteParamsAndQuery<Params>>();
  const closed = createEvent();
  const navigated = createEvent<RouteParamsAndQuery<Params>>();

  $isOpened.on(opened, () => true).on(closed, () => false);
  $params.on([opened, updated], (_, { params }) => params).reset(closed);
  $query.on([opened, updated], (_, { query }) => query).reset(closed);

  sample({
    clock: [opened, updated],
    target: navigated,
  });

  split({
    source: navigateFx.doneData,
    match: $isOpened.map((isOpened) => (isOpened ? 'updated' : 'opened')),
    cases: {
      opened,
      updated,
    },
  });

  const instance = {
    navigate: navigateFx,
    navigated,
    open: openFx,
    opened,
    updated,
    closed,
    $isOpened,
    $params,
    $query,
    kind: Kind.ROUTE,
  };

  return instance;
}
