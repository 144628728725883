import * as Yup from 'yup';

import { declOfNum } from '../utils';

const getDoubles = (array: string[]) => {
  const countes = array.reduce(
    (acc, item) => ({
      ...acc,
      [item]: acc[item] ? acc[item] + 1 : 1,
    }),
    {} as Record<string, number>,
  );
  const tuple = Object.entries(countes).filter(([_, value]) => value !== 1);
  return tuple;
};

const getErrorMessage = ({ tuple }: { tuple: [string, number][] }) =>
  `Среди тегов есть повторяющиеся значения:${tuple.map(
    ([key, value]) =>
      ` тег ${key} повторяется ${value} ${declOfNum(value, ['раз', 'раза', 'раз'])}`,
  )}.`;

/**
 * @example
 * ```ts
 * schema: Yup.string().uniqueString(),
 * ```
 */
export const addUniqStringMethod = () => {
  Yup.addMethod(Yup.string, 'uniqueString', function (message) {
    return this.test('uniqueString', message, function (str) {
      if (!str) {
        // потомучто если поле пустое это не значит что оно не валидное,
        // за валидность отвечает Yup.strin().required()
        return true;
      }
      const array = str.split(',').map((substr) => substr.trim());
      const isUniq = array.length === new Set(array).size;
      if (isUniq) {
        return true;
      }
      const tupleOfDoubles = getDoubles(array);
      return this.createError({
        message: getErrorMessage({ tuple: tupleOfDoubles }),
        params: {
          tuple: tupleOfDoubles,
        },
      });
    });
  });
};
