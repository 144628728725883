import { I18n } from '@lingui/core';
import { signal } from '@solid-kit/aria';
import {
  Accessor,
  createContext,
  onCleanup,
  onMount,
  ParentProps,
  Show,
  useContext,
} from 'solid-js';

export type I18nContext = {
  i18n: I18n;
  _: I18n['_'];
};

export type I18nProviderProps = Omit<I18nContext, '_'> & ParentProps;

export const LinguiContext = createContext<Accessor<I18nContext> | null>(null);

export const useLinguiInternal = (devErrorMessage?: string): I18nContext => {
  const context = useContext(LinguiContext);

  if (context == null) {
    throw new Error(devErrorMessage ?? 'useLingui hook was used without I18nProvider.');
  }

  return {
    get i18n() {
      return context().i18n;
    },
    _(id: any, values: any, options: any) {
      return context()._(id, values, options);
    },
  } as I18nContext;
};

export function useLingui(): I18nContext {
  return useLinguiInternal();
}

export function I18nProvider(props: I18nProviderProps) {
  const context = signal<I18nContext | null>(null);

  const updateContext = () => {
    context.set({
      i18n: props.i18n,
      _: props.i18n._.bind(props.i18n),
    });
  };

  onMount(() => {
    const unsubscribe = props.i18n.on('change', updateContext);

    updateContext();
    onCleanup(unsubscribe);
  });

  return (
    <Show when={context()}>
      {(ctx) => <LinguiContext.Provider value={ctx} children={props.children} />}
    </Show>
  );
}
