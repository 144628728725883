import { css } from '@linaria/core';

export const tableCell = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const fixed = css`
  position: sticky;
  left: 1rem;
  justify-content: flex-start;
`;
