import { registerPlatformService } from '@shared/lib/composer';

import * as model from './model';
import { DeleteAccountModal } from './ui';

export const accountDeleteService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: model.start,
  },
  api: {
    inputs: {
      formSubmit: model.$$deleteAccountForm.submit,
    },
    outputs: {
      $isSubmitDisabled: model.$isSubmitDisabled,
    },
    containers: {
      DeleteAccountModal,
    },
  },
});
