import { registerPlatformService } from '@shared/lib/composer';

import { sessionModel } from './model';

export const sessionService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: sessionModel.inputs.start,
    stop: sessionModel.inputs.stop,
  },
  api: {
    ...sessionModel,
  },
});
