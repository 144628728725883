import { Drawer } from '@solid-kit/ui';
import { ParentProps } from 'solid-js';

import { AppGuide } from '@services/app/guide';
import { AppHeader } from '@services/app/header';

import { breakpointsModel } from '@shared/services/breakpoints';
import { Layout, layoutModel } from '@shared/services/layout';

import { drawerWidth } from './index.css';

export function BaseLayout(props: ParentProps) {
  const breakpoints = breakpointsModel.useModel();
  const layout = layoutModel.useModel();

  return (
    <Layout>
      <Layout.Header fixed>
        <AppHeader />
      </Layout.Header>

      <Layout.Sider collapsed={layout.sidebarIsCollapsed()}>
        <Drawer
          persistent={breakpoints.is_md()}
          opened={layout.sidebarIsOpened()}
          customClass={drawerWidth}
          onClose={layout.sidebarClosed}
        >
          <AppGuide />
        </Drawer>
      </Layout.Sider>

      <Layout.Main collapsed={layout.sidebarIsCollapsed()}>
        <Layout.Content>{props.children}</Layout.Content>
      </Layout.Main>
    </Layout>
  );
}
