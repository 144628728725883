import { useUnit } from 'effector-solid';

import { registerPlatformModel } from '@shared/lib/composer';

import { coreModel } from './model';

export const breakpointsModel = registerPlatformModel({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: coreModel.inputs.start,
    stop: coreModel.inputs.stop,
  },
  api: {
    outputs: {
      ...coreModel.outputs,
    },
    useModel() {
      const { breakpoints } = coreModel.outputs;
      return useUnit({
        is_base: breakpoints.base.$matches,
        isDesktop: breakpoints.isDesktop.$matches,
        is_md: breakpoints.md.$matches,
        is_lg: breakpoints.lg.$matches,
        is_xl: breakpoints.xl.$matches,
        is_xxl: breakpoints.xxl.$matches,
        is_xxxl: breakpoints.xxxl.$matches,
      });
    },
  },
});
