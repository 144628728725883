import { registerPlatformService } from '@shared/lib/composer';

import { coreModel } from './model';
import {
  $withChannelToken,
  $withUserToken,
  createRequestEffect,
  fillChannelToken,
  fillUserToken,
} from './request';

export const apiService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: coreModel.inputs.start,
    stop: coreModel.inputs.stop,
  },
  api: {
    inputs: {
      ...coreModel.inputs,
    },
    outputs: {
      ...coreModel.outputs,
    },
    internals: {
      ...coreModel.internals,
    },
    experiments: {
      createRequestEffect,
      $withChannelToken,
      $withUserToken,
      fillUserToken,
      fillChannelToken,
    },
  },
});
