import { Fragment } from '@solid-kit/ui';
import { type Component } from 'solid-js';
import { Portal } from 'solid-js/web';
import { Toaster } from 'solid-toast';

export const withToaster = (WrappedComponent: Component) => (props) => {
  return (
    <Fragment>
      <WrappedComponent {...props} />
      <Portal>
        <Toaster position="bottom-left" />
      </Portal>
    </Fragment>
  );
};
