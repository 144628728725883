import { css } from '@linaria/core';
import { styled } from '@solidex/linaria';

export const Root = styled.div`
  --gaide-scroll-width: 1rem;
  --gaide-scroll-height: 5.6rem;

  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  margin-top: 1rem;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  overscroll-behavior: contain;

  &::-webkit-scrollbar-thumb {
    height: var(--gaide-scroll-height);
    border: 0.2rem solid transparent;
    border-radius: var(--gaide-scroll-width);
    background-color: transparent;
    background-clip: content-box;
  }

  &::-webkit-scrollbar {
    width: var(--gaide-scroll-width);
    background: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(235, 235, 235, 1);
  }
`;

export const Box = styled.div`
  &[data-type='section'] {
    padding: 1rem var(--layout-root-padding) 1rem var(--layout-root-padding);
  }

  &[data-type='section']:last-child {
    padding-bottom: 2rem;
  }
`;

export const profile = css`
  padding-left: 1rem;
`;
