import type { AxiosRequestConfig } from 'axios';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';
import type { Effect, EffectParams, Store } from 'effector';

import { createBarrier } from '@shared/lib/barrier';
import { Nullable } from '@shared/types';

import { channelTokenBarrier, jwtTokenBarrier } from './barriers';

type Headers = AxiosRequestConfig['headers'];

const start = createEvent();
const stop = createEvent();
const fillJwt = createEvent<string | null>();
const fillChannelToken = createEvent<string | null>();

const $jwtToken = createStore<Nullable<string>>(null).on(fillJwt, (_, token) => token);
const $channelToken = createStore<Nullable<string>>(null).on(fillChannelToken, (_, token) => token);

const withHeaders = <T>({ source, fn }: { source: Store<T>; fn: (headers: T) => Headers }) => {
  return <E extends Effect<any, any, any>>(effect: E) => {
    return attach({
      effect,
      source,
      mapParams: (params: EffectParams<E>, source) => {
        return {
          ...params,
          options: {
            ...params.options,
            headers: {
              ...params.options?.headers,
              ...fn(source),
            },
          },
        };
      },
    });
  };
};

const withJWT = withHeaders({
  source: $jwtToken,
  fn: (token) => (token ? { Authorization: `Bearer ${token}` } : {}),
});

const withChannelToken = withHeaders({
  source: $channelToken,
  fn: (token) => (token ? { Authorization: `Bearer ${token}` } : {}),
});

const createApiRequest = <Params, Options>(config: {
  handler: (params: Params, options: AxiosRequestConfig) => Promise<Options>;
  modificators?: Array<(effect: Effect<any, any, any>) => Effect<any, any, any>>;
  barriers?: ReturnType<typeof createBarrier>[];
}) => {
  let apiRequestFx = createEffect<{ params: Params; options: AxiosRequestConfig }, Options>(
    ({ params, options = {} }) => config.handler(params, options),
  );

  if (config.modificators && config.modificators.length > 0) {
    let handleFx = apiRequestFx;
    config.modificators.forEach((modificator) => {
      handleFx = modificator(apiRequestFx);
    });

    apiRequestFx = handleFx;
  }

  if (config.barriers && config.barriers.length > 0) {
    sample({
      clock: apiRequestFx.fail,
      target: config.barriers.map((i) => i.__.requestFailed),
    });
  }

  const requestFx = attach({
    source: (config.barriers || []).map((i) => i.__.$mutex),
    effect: async (mutexList, { params }: { params: Params }) => {
      for (const mutex of mutexList) {
        if (mutex?.isLocked) {
          await mutex?.waitForUnlock();
        }
      }

      const config = { params, options: {} };

      return apiRequestFx(config).catch(async (cause) => {
        for (const mutex of mutexList) {
          if (mutex?.isLocked) {
            await mutex?.waitForUnlock();
            return apiRequestFx(config);
          }
        }

        console.error('requestFx:', cause);
        throw cause;
      });
    },
  });

  return createEffect<Params, Options>((params) => requestFx({ params }));
};

export const coreModel = {
  inputs: {
    withHeaders,
    createApiRequest,
    withJWT,
    withChannelToken,
    start,
    stop,
  },
  outputs: {
    channelTokenBarrier,
    jwtTokenBarrier,
  },
  internals: {
    fillJwt,
    fillChannelToken,
  },
};
