import { type Component, Show } from 'solid-js';

import { Provider, routerModel } from '@shared/services/router';

export const withRouter = (WrappedComponent: Component) => (props) => {
  const { isReady } = routerModel.useModel();

  return (
    <Show when={isReady()}>
      <Provider router={routerModel.internals.router}>
        <WrappedComponent {...props} />
      </Provider>
    </Show>
  );
};
