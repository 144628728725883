import { css } from '@linaria/core';
import { tokens } from '@solid-kit/styles';

const styles = {
  // -- text align
  text_left: css`
    text-align: left;
  `,
  text_center: css`
    text-align: center;
  `,
  text_right: css`
    text-align: right;
  `,

  // -- brand color
  color_red: css`
    color: ${tokens.color.red};
  `,

  color_green: css`
    color: ${tokens.color.green};
  `,

  color_grey: css`
    color: ${tokens.color.black_500};
  `,
};

export const styleUtils = {
  text_align(props: 'left' | 'center' | 'right') {
    return styles[`text_${props || 'left'}`];
  },

  color(colorName: 'red' | 'green' | 'grey') {
    return styles[`color_${colorName}`];
  },
};
