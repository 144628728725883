import { cx } from '@linaria/core';
import { ic_m_placeholder } from '@rteam-fe/icons';
import { createHover } from '@solid-kit/aria';
import { heading } from '@solid-kit/styles/typography.css';
import { Avatar, DataCell, Icon } from '@solid-kit/ui';
import { For, Match, Show, Switch } from 'solid-js';

import { NavLink } from '@shared/services/router';

import * as styles from './index.css';
import type { MenuOption, MenuOptionGroup } from '../types';

type PlatformMenuProps = {
  each: MenuOptionGroup[];
};

type MenuItemProps = {
  item: MenuOption;
};

function MenuItem(props: MenuItemProps) {
  const { hoverProps, isHovered } = createHover({});

  return (
    <NavLink
      to={props.item.href}
      class={styles.link}
      target={props.item.target ? '_blank' : ''}
      onClick={(e) => {
        if (props.item.action) {
          e.preventDefault();
          props.item.action?.();
        }
      }}
      {...hoverProps}
    >
      <DataCell
        as="span"
        class={cx(isHovered?.() && styles.hovered, styles.dataCell)}
        leftContent={
          <Switch fallback={<Icon size={20} source={ic_m_placeholder} />}>
            <Match when={props.item.icon}>{(icon) => <Icon size={20} source={icon()} />}</Match>
            <Match when={props.item.smile}>
              {(smile) => <span class={styles.smile}>{smile()}</span>}
            </Match>
            <Match when={props.item.image}>
              {(image) => <Avatar size={20} src={image()} rounded />}
            </Match>
          </Switch>
        }
        rightContent={<span class={cx(styles.listItem, heading[200])}>{props.item.content}</span>}
      />
    </NavLink>
  );
}

export function PlatformMenu(props: PlatformMenuProps) {
  return (
    <div class={styles.root}>
      <Show when={props.each.length > 0} keyed={false}>
        <For each={props.each}>
          {({ items, title, subtitle }) => (
            <div class={styles.section}>
              <Show when={title}>
                <div class={styles.sectionTitle}>{title}</div>
              </Show>
              <Show when={subtitle}>
                <div class={styles.sectionSubtitle}>{subtitle}</div>
              </Show>
              <div class={styles.list}>
                <For each={items}>{(item) => <MenuItem item={item} />}</For>
              </div>
            </div>
          )}
        </For>
      </Show>
    </div>
  );
}
