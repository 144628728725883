import { useUnit } from 'effector-solid';

import { registerPlatformModel } from '@shared/lib/composer';

import { router, routerStarted } from './core';

const {
  $href,
  $query,
  $search,
  $isReady,
  $history,
  $prevHref,
  $pathname,
  back,
  forward,
  navigateTo,
  reloadPage,
  openNewTab,
  routeNotFound,
  removeQueryParams,
  historyUpdated,
  replaceQueryParams,
} = router;

export const routerModel = registerPlatformModel({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: routerStarted,
  },
  api: {
    inputs: {
      back,
      forward,
      navigateTo,
      reloadPage,
      openNewTab,
      removeQueryParams,
      replaceQueryParams,
    },
    outputs: {
      $href,
      $query,
      $search,
      $isReady,
      $history,
      $pathname,
      $prevHref,
      routeNotFound,
      historyUpdated,
    },
    internals: {
      router,
    },
    useModel() {
      return useUnit({
        reloadPage,
        isReady: $isReady,
      });
    },
  },
});
