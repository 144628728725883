/**
 * @nullable
 */
export type MeShowResponseGender =
  | (typeof MeShowResponseGender)[keyof typeof MeShowResponseGender]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeShowResponseGender = {
  male: 'male',
  female: 'female',
} as const;
