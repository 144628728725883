import { css } from '@linaria/core';

export const input = css`
  display: block;
  width: 65px;
  height: 40px;
`;

export const inputSuccess = css`
  border-color: lightgreen !important;
`;

export const inputFailed = css`
  border-color: var(--color-red) !important;
`;
