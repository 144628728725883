export enum AppLanguage {
  ru = 'ru',
  en = 'en',
}

interface AppLanguageConfig {
  code: AppLanguage;
  name: string;
}

export const APP_LANGUAGES: AppLanguageConfig[] = [
  { code: AppLanguage.ru, name: 'Русский' },
  { code: AppLanguage.en, name: 'English' },
];
