import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';

import { Nullable } from '@shared/types';

const start = createEvent();
const stop = createEvent();

const $fetcher = createStore<Nullable<AxiosInstance>>(null).reset(stop);

const axiosFx = attach({
  source: $fetcher,
  effect: async (instance, params: AxiosRequestConfig) => {
    if (!instance) {
      console.warn('Create axios instance');
      instance = axios.create();
    }

    return instance.request(params);
  },
});

const requestFx = createEffect({
  handler: async (request: AxiosRequestConfig) => {
    const response = await axiosFx(request)
      .then(({ data }) => data)
      .catch((cause) => {
        throw cause;
      });

    return response;
  },
});

const createAxiosInstanceFx = createEffect(() => {
  const axiosInstance = axios.create();
  axiosInstance.defaults.maxRedirects = 0; // Set to 0 to prevent automatic redirects
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && [301, 302].includes(error.response.status)) {
        const redirectUrl = error.response.headers.location;
        return axiosInstance.get(redirectUrl);
      }
      return Promise.reject(error);
    },
  );
  return axiosInstance;
});

sample({
  clock: createAxiosInstanceFx.doneData,
  target: $fetcher,
});

sample({
  clock: start,
  target: createAxiosInstanceFx,
});

export const fetcherModel = {
  inputs: {
    start,
    stop,
    requestFx,
  },
  outputs: {
    $fetcher,
  },
};
