import { useUnit } from 'effector-solid';
import { Accessor } from 'solid-js';

import type { RouteInstance } from '../types';

export function createIsOpened(route: Accessor<RouteInstance<any> | RouteInstance<any>[]>) {
  const instance = route();
  const arr = Array.isArray(instance) ? instance.map((r) => r.$isOpened) : [instance.$isOpened];
  return () => useUnit(arr).some((r) => r());
}
