import type { Navigate, Route, RouteParams, RouteQuery } from '../types';

const trimPathRegex = /^\/+|(\/)\/+$/g;

export function getPagePath(path: string, params: RouteParams) {
  path = path
    .replace(/\/:\w+\?/g, (i) => {
      let param = params ? params[i.slice(2).slice(0, -1)] : null;
      if (param) {
        return '/' + encodeURIComponent(param);
      } else {
        return '';
      }
    })
    .replace(/\/:\w+/g, (i) => '/' + encodeURIComponent(params[i.slice(2)]));
  return path || '/';
}

export function buildPath<Params extends RouteParams>({
  route,
  params,
  query = {},
}: {
  route: Route;
  params?: Params;
  query?: RouteQuery;
}) {
  const serializedParams = new URLSearchParams(query);
  const pathname = getPagePath(route.pattern, params || {});
  const search = Object.keys(query).length ? `?${serializedParams}` : '';

  return `${pathname}${search}`;
}

export const navigateToPath = (params: Navigate) => {
  return `${params.pathname ?? ''}${params.search ? `?${params.search}` : ''}`;
};

export function normalizePath(path: string, omitSlash: boolean = false) {
  const s = path.replace(trimPathRegex, '$1');
  return s ? (omitSlash || /^[?#]/.test(s) ? s : '/' + s) : '';
}

export function joinPaths(from: string, to: string): string {
  return normalizePath(from).replace(/\/*(\*.*)?$/g, '') + normalizePath(to);
}
