import { createApi, createStore } from 'effector';

import type { Modal } from '@shared/services/modals';

const $activeModals = createStore<Modal[]>([]);
const $isModalActive = $activeModals.map((modals) => modals.length > 0);

const modalControls = createApi($activeModals, {
  openModal: (modals, modal: Modal) =>
    !modals.flatMap((modal) => modal.name).includes(modal.name) ? [...modals, modal] : modals,
  closeModal: (modals) => modals.slice(0, -1),
  clearAllModals: () => [],
});

export const coreModel = {
  inputs: {
    modalControls,
  },
  outputs: {
    $activeModals,
    $isModalActive,
  },
};
