import { Trans } from '@lingui/macro';
import { ic_m_log_out } from '@rteam-fe/icons';
import { Avatar, DataCell, Icon, Stack } from '@solid-kit/ui';
import { Text } from '@solid-kit/ui/v2';
import { useUnit } from 'effector-solid';
import { Show } from 'solid-js';

import { sessionService } from '@services/session';

import { type Profile } from '@entities/profile';

import { Link } from '@shared/services/router';
import { PlatformMenu } from '@shared/ui/platform-menu';

import { mainMenuMap } from '../config';
import { Box, Container, profile, Root } from './index.css';

export function AppGuide() {
  const { profile } = useUnit({
    profile: sessionService.outputs.$profile,
  });

  return <Show when={profile()}>{(profile) => <AppGuideReady profile={profile()} />}</Show>;
}

function AppGuideReady(props: { profile: Profile }) {
  return (
    <Root>
      <Container>
        <Box data-type="section">
          <Stack direction="column" class={profile}>
            <DataCell
              leftContent={
                <Avatar
                  alt={`${props.profile.firstName} ${props.profile.lastName}`}
                  rounded
                  size={40}
                />
              }
              content={
                <Stack direction="column" spacing={0}>
                  <Text variant="header-1" ellipsisLines={2}>
                    {props.profile.firstName} {props.profile.lastName}
                  </Text>

                  <Link
                    class="exitLink"
                    href="/user/logout/"
                    whenClick={() => {
                      window.location.href = '/user/logout/';
                    }}
                  >
                    <Text variant="body-1">
                      <Trans>
                        Выйти <Icon size={12} source={ic_m_log_out} />
                      </Trans>
                    </Text>
                  </Link>
                </Stack>
              }
            />
          </Stack>
        </Box>

        <Box data-type="section">
          <PlatformMenu each={mainMenuMap()} />
        </Box>
      </Container>
    </Root>
  );
}
