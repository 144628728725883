import { useUnit } from 'effector-solid';

import { AnyFormValues, Field, Form, ValidationError } from './types';

type ErrorTextMap = {
  [key: string]: string;
};

type AddErrorPayload = { rule: string; errorText?: string };

type ConnectedField<Value> = {
  name: string;
  value: Value;
  errors: ValidationError<Value>[];
  firstError: ValidationError<Value> | null;
  hasError: () => boolean;
  onChange: (v: Value) => Value;
  onBlur: (v: void) => void;
  errorText: (map?: ErrorTextMap) => string;
  addError: (p: AddErrorPayload) => AddErrorPayload;
  validate: (v: void) => void;
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  touched: boolean;
  reset: (v: void) => void;
  set: (v: Value) => Value;
  setInitial: (v: Value) => Value;
  resetErrors: (v: void) => void;
};

type ConnectedFields<Values extends AnyFormValues> = {
  [K in keyof Values]: ConnectedField<Values[K]>;
};

type AnyConnectedFields = {
  [key: string]: ConnectedField<any>;
};

export function useField<Value>(field: Field<Value>): ConnectedField<Value> {
  const formField = useUnit(field.$field);

  return {
    get name() {
      return field.name;
    },
    get value() {
      return formField().value;
    },
    get errors() {
      return formField().errors;
    },
    get firstError() {
      return formField().firstError;
    },
    get isValid() {
      return formField().isValid;
    },
    get isDirty() {
      return formField().isDirty;
    },
    get touched() {
      return formField().isTouched;
    },
    get isTouched() {
      return formField().isTouched;
    },
    onChange: useUnit(field.onChange),
    onBlur: useUnit(field.onBlur),
    addError: useUnit(field.addError),
    validate: useUnit(field.validate),
    reset: useUnit(field.reset),
    set: useUnit(field.onChange),
    setInitial: useUnit(field.setInitial),
    resetErrors: useUnit(field.resetErrors),
    hasError: () => {
      return formField().firstError !== null;
    },
    errorText: (map) => {
      const { firstError } = formField();

      if (!firstError) {
        return '';
      }
      if (!map) {
        return firstError.errorText || '';
      }
      if (map[firstError.rule]) {
        return map[firstError.rule];
      }
      return firstError.errorText || '';
    },
  };
}

type Result<Values extends AnyFormValues> = {
  fields: ConnectedFields<Values>;
  values: Values;
  hasError: (fieldName?: keyof Values) => boolean;
  eachValid: boolean;
  isValid: boolean;
  isDirty: boolean;
  isTouched: boolean;
  touched: boolean;
  errors: (fieldName: keyof Values) => ValidationError<Values[typeof fieldName]>[];
  error: (fieldName: keyof Values) => ValidationError<Values[typeof fieldName]> | null;
  errorText: (fieldName: keyof Values, map?: ErrorTextMap) => string;
  submit: (p: void) => void;
  reset: (p: void) => void;
  setForm: (p: Partial<Values>) => Partial<Values>;
  setInitialForm: (p: Partial<Values>) => Partial<Values>;
  set: (p: Partial<Values>) => Partial<Values>;
  formValidated: (p: Values) => Values;
};

export type FormResult<Values extends AnyFormValues> = Result<Values>;

export function useForm<Values extends AnyFormValues>(form: Form<Values>): Result<Values> {
  const connectedFields = {} as AnyConnectedFields;
  const values = {} as AnyFormValues;

  for (const fieldName in form.fields) {
    if (!form.fields.hasOwnProperty(fieldName)) continue;
    const field = form.fields[fieldName];
    const connectedField = useField(field);
    connectedFields[fieldName] = connectedField;
    values[fieldName] = connectedField.value;
  }

  const formMeta = useUnit(form.$meta);

  const hasError = (fieldName?: string): boolean => {
    if (!fieldName) {
      return !formMeta().isValid;
    }
    if (connectedFields[fieldName]) {
      return Boolean(connectedFields[fieldName].firstError);
    }
    return false;
  };

  const error = (fieldName: string) => {
    if (connectedFields[fieldName]) {
      return connectedFields[fieldName].firstError;
    }
    return null;
  };

  const errors = (fieldName: string) => {
    if (connectedFields[fieldName]) {
      return connectedFields[fieldName].errors;
    }
    return [];
  };

  const errorText = (fieldName: string, map?: ErrorTextMap) => {
    const field = connectedFields[fieldName];
    if (!field) {
      return '';
    }
    if (!field.firstError) {
      return '';
    }
    if (!map) {
      return field.firstError.errorText || '';
    }
    if (map[field.firstError.rule]) {
      return map[field.firstError.rule];
    }
    return field.firstError.errorText || '';
  };

  return {
    fields: connectedFields as ConnectedFields<Values>,
    values,
    hasError,
    get eachValid() {
      return formMeta().isValid;
    },
    get isValid() {
      return formMeta().isValid;
    },
    get isDirty() {
      return formMeta().isDirty;
    },
    get isTouched() {
      return formMeta().touched;
    },
    get touched() {
      return formMeta().touched;
    },
    errors,
    error,
    errorText,
    reset: useUnit(form.reset),
    submit: useUnit(form.submit),
    setForm: useUnit(form.setForm),
    setInitialForm: useUnit(form.setInitialForm),
    set: useUnit(form.setForm), // set form alias
    formValidated: useUnit(form.formValidated),
  } as Result<Values>;
}
