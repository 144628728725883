import { css } from '@linaria/core';

export const tableBody = css`
  display: grid;
  gap: 1rem;
`;

export const tableBodyRow = css`
  padding: 1rem;
  border-radius: 1rem;
  background-color: var(--base-bg-color);
  box-shadow: inset 0 0 0 1px var(--color-grey-light);
`;
