import { lazily } from '@shared/lib/lazily';
import { routes } from '@shared/services/router';

export const profilePage = {
  route: routes.profile,
  view: lazily(() => import('./ui')).ProfileScreen,
  load: async () => {
    await Promise.all([import('@services/profile/profile-edit')]);
  },
};
