import { requestFx } from '../instance';
import type {
  AuthCodeResendSuccessResponse,
  DeleteFrontendApiV1ProfileMeDeleteParams,
  DeleteFrontendApiV1ProfileSessionsDeleteParams,
  EmailVerifiedHandlerRequest,
  GetFrontendApiV1ProfileMeShowParams,
  GetFrontendApiV1ProfileSessionsCurrentParams,
  GetFrontendApiV1ProfileSessionsList200,
  GetFrontendApiV1ProfileSessionsListParams,
  GetFrontendApiV1ProfileSessionsShowParams,
  LoginCredentialsRequest,
  LoginDuoAuthRequest,
  LoginSuccessResponse,
  MeChangeEmailRequest,
  MeChangePasswordRequest,
  MeDeleteRequest,
  MeShowResponse,
  MeUpdateRequest,
  PatchFrontendApiV1ProfileMeUpdateParams,
  PostFrontendApiV1AuthAuthCodeResendParams,
  PostFrontendApiV1AuthEmailVerifiedHandlerParams,
  PostFrontendApiV1AuthLoginCredentials200,
  PostFrontendApiV1AuthLoginCredentialsParams,
  PostFrontendApiV1AuthLoginDuoAuthParams,
  PostFrontendApiV1AuthRegistrationPhoneConfirm200,
  PostFrontendApiV1AuthRegistrationPhoneConfirmParams,
  PostFrontendApiV1AuthRegistrationProfileParams,
  PostFrontendApiV1AuthRestorePasswordChangeParams,
  PostFrontendApiV1AuthRestorePasswordPhoneConfirm200,
  PostFrontendApiV1AuthRestorePasswordPhoneConfirmParams,
  PostFrontendApiV1ProfileMeChangeEmailParams,
  PostFrontendApiV1ProfileMeChangePasswordParams,
  PostFrontendApiV1ProfileMeEmailVerifiedSendParams,
  PostFrontendApiV1ProfileSessionsDeleteAllParams,
  RegistrationPhoneConfirmRequest,
  RegistrationProfileRequest,
  RestorePasswordChangeRequest,
  RestorePasswordPhoneConfirmRequest,
  SessionShowResponse,
} from './schemas';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Просмотр информации о своём профиле.
 */
export const getFrontendApiV1ProfileMeShow = (
  params?: GetFrontendApiV1ProfileMeShowParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<MeShowResponse>({ url: `/v1/profile/me/`, method: 'GET', params }, options);
};

/**
 * @summary Удаление аккаунта.
 */
export const deleteFrontendApiV1ProfileMeDelete = (
  meDeleteRequest: MeDeleteRequest,
  params?: DeleteFrontendApiV1ProfileMeDeleteParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    {
      url: `/v1/profile/me/`,
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      data: meDeleteRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Изменение своего профиля.
 */
export const patchFrontendApiV1ProfileMeUpdate = (
  meUpdateRequest: MeUpdateRequest,
  params?: PatchFrontendApiV1ProfileMeUpdateParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    {
      url: `/v1/profile/me/`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: meUpdateRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Изменение e-mail.
 */
export const postFrontendApiV1ProfileMeChangeEmail = (
  meChangeEmailRequest: MeChangeEmailRequest,
  params?: PostFrontendApiV1ProfileMeChangeEmailParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    {
      url: `/v1/profile/me/change-email/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: meChangeEmailRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Изменение пароля.
 */
export const postFrontendApiV1ProfileMeChangePassword = (
  meChangePasswordRequest: MeChangePasswordRequest,
  params?: PostFrontendApiV1ProfileMeChangePasswordParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    {
      url: `/v1/profile/me/change-password/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: meChangePasswordRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Отправка повторного письма для подтверждения e-mail адреса.
 */
export const postFrontendApiV1ProfileMeEmailVerifiedSend = (
  params?: PostFrontendApiV1ProfileMeEmailVerifiedSendParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    { url: `/v1/profile/me/email-verified/send/`, method: 'POST', params },
    options,
  );
};

/**
 * @summary Просмотр информации о текущей сессии пользователя.
 */
export const getFrontendApiV1ProfileSessionsCurrent = (
  params?: GetFrontendApiV1ProfileSessionsCurrentParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<SessionShowResponse>(
    { url: `/v1/profile/sessions/current/`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Просмотр информации о сессии пользователя.
 */
export const getFrontendApiV1ProfileSessionsShow = (
  id: string,
  params?: GetFrontendApiV1ProfileSessionsShowParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<SessionShowResponse>(
    { url: `/v1/profile/sessions/${id}/`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Удаление сессии пользователя.
 */
export const deleteFrontendApiV1ProfileSessionsDelete = (
  id: string,
  params?: DeleteFrontendApiV1ProfileSessionsDeleteParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>({ url: `/v1/profile/sessions/${id}/`, method: 'DELETE', params }, options);
};

/**
 * @summary Получение списка сессий пользователя.
 */
export const getFrontendApiV1ProfileSessionsList = (
  params?: GetFrontendApiV1ProfileSessionsListParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<GetFrontendApiV1ProfileSessionsList200>(
    { url: `/v1/profile/sessions/`, method: 'GET', params },
    options,
  );
};

/**
 * @summary Удаление всех сессий пользователя (исключая текущую).
 */
export const postFrontendApiV1ProfileSessionsDeleteAll = (
  params?: PostFrontendApiV1ProfileSessionsDeleteAllParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    { url: `/v1/profile/sessions/delete-all/`, method: 'POST', params },
    options,
  );
};

/**
 * @summary Ввод номера телефона/e-mail и пароля.
 */
export const postFrontendApiV1AuthLoginCredentials = (
  loginCredentialsRequest: LoginCredentialsRequest,
  params?: PostFrontendApiV1AuthLoginCredentialsParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<PostFrontendApiV1AuthLoginCredentials200>(
    {
      url: `/v1/auth/login/credentials/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginCredentialsRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Подтверждение двухфакторной авторизации.
 */
export const postFrontendApiV1AuthLoginDuoAuth = (
  loginDuoAuthRequest: LoginDuoAuthRequest,
  params?: PostFrontendApiV1AuthLoginDuoAuthParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<LoginSuccessResponse>(
    {
      url: `/v1/auth/login/duo-auth/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginDuoAuthRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Подтверждение номера телефона пользователя.
 */
export const postFrontendApiV1AuthRegistrationPhoneConfirm = (
  registrationPhoneConfirmRequest: RegistrationPhoneConfirmRequest,
  params?: PostFrontendApiV1AuthRegistrationPhoneConfirmParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<PostFrontendApiV1AuthRegistrationPhoneConfirm200>(
    {
      url: `/v1/auth/registration/phone-confirm/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationPhoneConfirmRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Регистрация профиля пользователя.
 */
export const postFrontendApiV1AuthRegistrationProfile = (
  registrationProfileRequest: RegistrationProfileRequest,
  params?: PostFrontendApiV1AuthRegistrationProfileParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<LoginSuccessResponse>(
    {
      url: `/v1/auth/registration/profile/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationProfileRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Подтверждение номера телефона пользователя.
 */
export const postFrontendApiV1AuthRestorePasswordPhoneConfirm = (
  restorePasswordPhoneConfirmRequest: RestorePasswordPhoneConfirmRequest,
  params?: PostFrontendApiV1AuthRestorePasswordPhoneConfirmParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<PostFrontendApiV1AuthRestorePasswordPhoneConfirm200>(
    {
      url: `/v1/auth/restore-password/phone-confirm/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: restorePasswordPhoneConfirmRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Изменение пароля пользователя.
 */
export const postFrontendApiV1AuthRestorePasswordChange = (
  restorePasswordChangeRequest: RestorePasswordChangeRequest,
  params?: PostFrontendApiV1AuthRestorePasswordChangeParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    {
      url: `/v1/auth/restore-password/change/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: restorePasswordChangeRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Процессинг подтверждения e-mail адреса на основе токена из e-mail письма.
 */
export const postFrontendApiV1AuthEmailVerifiedHandler = (
  emailVerifiedHandlerRequest: EmailVerifiedHandlerRequest,
  params?: PostFrontendApiV1AuthEmailVerifiedHandlerParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<void>(
    {
      url: `/v1/auth/email-verified/handler/`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: emailVerifiedHandlerRequest,
      params,
    },
    options,
  );
};

/**
 * @summary Повторно отправить код авторизации (подтверждение номера телефона/e-mail адреса/etc).
 */
export const postFrontendApiV1AuthAuthCodeResend = (
  id: string,
  params?: PostFrontendApiV1AuthAuthCodeResendParams,
  options?: SecondParameter<typeof requestFx>,
) => {
  return requestFx<AuthCodeResendSuccessResponse>(
    { url: `/v1/auth/auth-code/resend/${id}/`, method: 'POST', params },
    options,
  );
};

export type GetFrontendApiV1ProfileMeShowResult = NonNullable<
  Awaited<ReturnType<typeof getFrontendApiV1ProfileMeShow>>
>;
export type DeleteFrontendApiV1ProfileMeDeleteResult = NonNullable<
  Awaited<ReturnType<typeof deleteFrontendApiV1ProfileMeDelete>>
>;
export type PatchFrontendApiV1ProfileMeUpdateResult = NonNullable<
  Awaited<ReturnType<typeof patchFrontendApiV1ProfileMeUpdate>>
>;
export type PostFrontendApiV1ProfileMeChangeEmailResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1ProfileMeChangeEmail>>
>;
export type PostFrontendApiV1ProfileMeChangePasswordResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1ProfileMeChangePassword>>
>;
export type PostFrontendApiV1ProfileMeEmailVerifiedSendResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1ProfileMeEmailVerifiedSend>>
>;
export type GetFrontendApiV1ProfileSessionsCurrentResult = NonNullable<
  Awaited<ReturnType<typeof getFrontendApiV1ProfileSessionsCurrent>>
>;
export type GetFrontendApiV1ProfileSessionsShowResult = NonNullable<
  Awaited<ReturnType<typeof getFrontendApiV1ProfileSessionsShow>>
>;
export type DeleteFrontendApiV1ProfileSessionsDeleteResult = NonNullable<
  Awaited<ReturnType<typeof deleteFrontendApiV1ProfileSessionsDelete>>
>;
export type GetFrontendApiV1ProfileSessionsListResult = NonNullable<
  Awaited<ReturnType<typeof getFrontendApiV1ProfileSessionsList>>
>;
export type PostFrontendApiV1ProfileSessionsDeleteAllResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1ProfileSessionsDeleteAll>>
>;
export type PostFrontendApiV1AuthLoginCredentialsResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthLoginCredentials>>
>;
export type PostFrontendApiV1AuthLoginDuoAuthResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthLoginDuoAuth>>
>;
export type PostFrontendApiV1AuthRegistrationPhoneConfirmResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthRegistrationPhoneConfirm>>
>;
export type PostFrontendApiV1AuthRegistrationProfileResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthRegistrationProfile>>
>;
export type PostFrontendApiV1AuthRestorePasswordPhoneConfirmResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthRestorePasswordPhoneConfirm>>
>;
export type PostFrontendApiV1AuthRestorePasswordChangeResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthRestorePasswordChange>>
>;
export type PostFrontendApiV1AuthEmailVerifiedHandlerResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthEmailVerifiedHandler>>
>;
export type PostFrontendApiV1AuthAuthCodeResendResult = NonNullable<
  Awaited<ReturnType<typeof postFrontendApiV1AuthAuthCodeResend>>
>;
