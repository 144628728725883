import { useUnit } from 'effector-solid';
import { type Component } from 'solid-js';

import { sessionService } from '@services/session';

export const withSession = (WrappedComponent: Component) => (props) => {
  useUnit({
    isAuthorized: sessionService.outputs.$isAuthorized,
    isSucceeded: sessionService.outputs.$isSucceeded,
  });

  return <WrappedComponent {...props} />;
};
