import { css } from '@linaria/core';
import 'sanitize.css';

import { screenSizes } from './screen-sizes';

export const globalCss = css`
  :global() {
    :root {
      --rtds-back-drop-z-index: 1000;
      --header-height: 4rem;
      --sider-width: 273px;
      --modal-width: 60vw;
      --modal-max-width: 90vw;
      --modal-min-width: 10vw;
      --modal-z-index: 100;

      /* цвет который должен быть в SolidKit */
      --color-light-light-grey: #f6f6f6;

      @media (min-width: ${screenSizes.sm}px) {
        --header-height: 6rem;
      }

      @media (min-width: ${screenSizes.lg}px) {
        --header-height: 6.4rem;
      }
    }

    html {
      box-sizing: border-box;
      font-size: 10px;
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    body {
      overflow: hidden;
      overflow-y: scroll;
      min-width: var(--width-phone);
      margin: 0;
      padding: 0;
      background-color: var(--color-main-bg);
      color: var(--color-black);
      font-weight: 400;
      font-style: normal;
      font-variant: normal;
      font-size: 1rem;
      font-family: var(--font-main);
      line-height: 1.3;
      letter-spacing: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: auto;
      overflow-anchor: none;
      text-rendering: optimizespeed;
      overscroll-behavior-y: none;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0;
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
    }

    #root {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      min-height: 100%;
    }

    div[data-popper-placement] {
      z-index: 101;
    }

    .solid-kit-popover {
      z-index: 101 !important;
    }

    svg.platfrom-icon-ic_m_shorts_logo {
      path:last-child {
        fill: currentColor;
      }
    }
  }
`;
