import compose from 'compose-function';

import { withEffector } from './with-effector';
import { withI18n } from './with-i18n';
import { withMeta } from './with-meta';
import { withModal } from './with-modal';
import { withRouter } from './with-router';
import { withSession } from './with-session';
import { withToaster } from './with-toaster';
import { withUIEnvironment } from './with-ui-env';

export const withHocs = compose(
  withUIEnvironment,
  withEffector,
  withI18n,
  withMeta,
  withRouter,
  withModal,
  withToaster,
  withSession,
);
