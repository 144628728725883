import { css } from '@linaria/core';

export const tableHead = css`
  display: grid;
  gap: 1rem;
  color: var(--color-black-50);
`;

export const ellipsised = css`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
