import { useUnit } from 'effector-solid';

import type { NavigateProps, RouteParams } from '../types';

export const Navigate = <Params extends RouteParams>(props: NavigateProps<Params>) => {
  const state = {
    get navigate() {
      return useUnit(props.to.navigate);
    },
    get config() {
      return {
        params: props.params || ({} as Params),
        query: props.query || {},
      };
    },
  };

  state.navigate(state.config);

  return null;
};
