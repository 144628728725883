/**
 * @description
 * https://realadmin.ru/coding/sklonenie-na-javascript.html
 * @param n number
 * @param text_forms string[]
 * @returns string
 *
 * @example
 * ```js
 * declOfNum(1, ['минута', 'минуты', 'минут']); // вернёт — минута
 * declOfNum(2, ['минута', 'минуты', 'минут']); // вернёт — минуты
 * declOfNum(5, ['минута', 'минуты', 'минут']); // вернёт — минут
 * ```
 */
export function declOfNum(n: number, text_forms: string[]) {
  n = Math.abs(n) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return text_forms[2];
  }
  if (n1 > 1 && n1 < 5) {
    return text_forms[1];
  }
  if (n1 == 1) {
    return text_forms[0];
  }
  return text_forms[2];
}
