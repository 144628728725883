import { createEffect, createEvent, sample } from 'effector';

import { createBrowserHistory } from '../lib/history';
import { routesMap } from '../routes';
import { createRouter } from './create-router';

const routerStarted = createEvent();

const router = createRouter({ routes: routesMap });
const createBrowserHistoryFx = createEffect(() => createBrowserHistory());

sample({
  clock: routerStarted,
  target: createBrowserHistoryFx,
});

sample({
  clock: createBrowserHistoryFx.doneData,
  target: router.setHistory,
});

export { router, routerStarted };
