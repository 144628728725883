import { registerPlatformService } from '@shared/lib/composer';

import { fetcherModel } from './model';

export const fetcherService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: fetcherModel.inputs.start,
    stop: fetcherModel.inputs.stop,
  },
  api: {
    inputs: {
      ...fetcherModel.inputs,
    },
    outputs: {
      ...fetcherModel.outputs,
    },
  },
});
