import { cx } from '@linaria/core';
import { createEffect } from 'solid-js';
import { Toast } from 'solid-toast';

import { getToastYDirection } from '../lib/toast-direction';
import { notificationRoot } from './index.css';

export type CustomNotificationProps = {
  message: string;
  toast: Toast;
};

export const CustomNotification = (props: CustomNotificationProps) => {
  let el: HTMLDivElement | undefined;

  createEffect(() => {
    if (!el) return;
    const direction = getToastYDirection(props.toast, 'bottom-center');
    if (props.toast.visible) {
      el.animate(
        [
          { transform: `translate3d(0,${direction * -200}%,0) scale(.6)`, opacity: 0.5 },
          { transform: 'translate3d(0,0,0) scale(1)', opacity: 1 },
        ],
        {
          duration: 350,
          fill: 'forwards',
          easing: 'cubic-bezier(.21,1.02,.73,1)',
        },
      );
    } else {
      el.animate(
        [
          { transform: 'translate3d(0,0,-1px) scale(1)', opacity: 1 },
          { transform: `translate3d(0,${direction * -150}%,-1px) scale(.4)`, opacity: 0 },
        ],
        {
          duration: 400,
          fill: 'forwards',
          easing: 'cubic-bezier(.06,.71,.55,1)',
        },
      );
    }
  });

  return (
    <div class={cx(notificationRoot, props.toast?.className)} ref={el}>
      {props.message}
    </div>
  );
};

export const createNotification = (props: CustomNotificationProps) => {
  return <CustomNotification {...props} />;
};
