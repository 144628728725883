import { createEffect, createEvent, sample } from 'effector';

import { routerModel } from './index';

function isSvg<T extends SVGElement>(el: T | HTMLElement): el is T {
  return el.namespaceURI === 'http://www.w3.org/2000/svg';
}

function isRouteAnchor(event: MouseEvent) {
  if (
    event.defaultPrevented ||
    event.button !== 0 ||
    event.metaKey ||
    event.altKey ||
    event.ctrlKey ||
    event.shiftKey
  ) {
    return false;
  }

  const a = event
    .composedPath()
    .find((el) => el instanceof Node && el.nodeName.toUpperCase() === 'A') as
    | HTMLAnchorElement
    | SVGAElement
    | undefined;

  if (!a || !a.hasAttribute('link')) return false;

  const svg = isSvg(a);
  const href = svg ? a.href.baseVal : a.href;
  const target = svg ? a.target.baseVal : a.target;
  if (target || (!href && !a.hasAttribute('state'))) return false;

  const rel = (a.getAttribute('rel') || '').split(/\s+/);
  if (a.hasAttribute('download') || (rel && rel.includes('external'))) return false;

  const url = svg ? new URL(href, document.baseURI) : new URL(href);

  if (url.origin !== window.location.origin) {
    return false;
  }

  return true;
}

const preventDefaultFx = createEffect((event: MouseEvent) => {
  event.preventDefault();
});

const anchorClicked = createEvent<{
  anchor: HTMLAnchorElement;
  event: MouseEvent;
}>();

const navigateFromRoute = createEvent<{
  anchor: HTMLAnchorElement;
  event: MouseEvent;
}>();

sample({
  clock: anchorClicked,
  filter: ({ event }) => isRouteAnchor(event),
  target: navigateFromRoute,
});

sample({
  clock: navigateFromRoute,
  fn: ({ event }) => event,
  target: preventDefaultFx,
});

sample({
  clock: navigateFromRoute,
  fn: ({ anchor }) => {
    const { pathname, searchParams } = new URL(anchor.href);

    return {
      pathname,
      search: searchParams.toString(),
    };
  },
  target: routerModel.inputs.navigateTo,
});

export { anchorClicked, isRouteAnchor };
