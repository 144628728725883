import * as styles from './index.css';
import { layoutModel } from '../../model';

export function Layout(props) {
  const layout = layoutModel.useModel();

  return (
    <styles.Root
      padding={layout.padding()}
      data-layout-type={layout.layoutType()}
      children={props.children}
    />
  );
}

Layout.Header = (props) => {
  return (
    <div
      class={styles.header}
      attr:fixed={props.fixed ? '' : null}
      attr:fullscreen={props.fullscreen ? '' : null}
      children={props.children}
    />
  );
};

Layout.Sider = (props) => {
  return (
    <div
      class={styles.sider}
      attr:collapsed={props.collapsed ? '' : null}
      children={props.children}
    />
  );
};

Layout.Main = (props) => {
  return (
    <div
      class={styles.main}
      attr:collapsed={props.collapsed ? '' : null}
      attr:fullscreen={props.fullscreen ? '' : null}
      children={props.children}
    />
  );
};

Layout.Content = (props) => {
  return <div class={styles.content}>{props.children}</div>;
};
