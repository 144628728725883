/**
 * @nullable
 */
export type MeUpdateRequestGender =
  | (typeof MeUpdateRequestGender)[keyof typeof MeUpdateRequestGender]
  | null;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MeUpdateRequestGender = {
  male: 'male',
  female: 'female',
} as const;
