import { useUnit } from 'effector-solid';
import { Accessor, createMemo, JSX, splitProps } from 'solid-js';

import { buildPath } from '../lib/path';
import { anchorClicked } from '../model/anchor';
import { isRoute } from '../model/is-route';
import type { RouteInstance, RouteParams, RouteQuery } from '../types';
import { useRouter } from './provider';

declare module 'solid-js' {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    interface AnchorHTMLAttributes<T> {
      link?: boolean;
    }
  }
}

export interface AnchorProps<Params extends RouteParams>
  extends JSX.AnchorHTMLAttributes<HTMLAnchorElement> {
  to?: RouteInstance<Params> | string;
  params?: Params;
  query?: RouteQuery;
  activeClass?: string;
  inactiveClass?: string;
  native?: boolean;
  whenClick?: (e: MouseEvent) => void;
}

function createRoute<Params extends RouteParams>(to: Accessor<AnchorProps<Params>['to']>) {
  if (!isRoute(to())) return null;

  const router = useRouter();
  const route = router.routesMap.find((routeObj) => routeObj.route === to());

  return route ?? null;
}

export function A<Params extends RouteParams>(props: AnchorProps<Params>) {
  const [splited, rest] = splitProps(props, [
    'to',
    'params',
    'query',
    'class',
    'activeClass',
    'inactiveClass',
    'href',
    'whenClick',
  ]);

  const route = createRoute(() => splited.to);

  const handleAnchorClick = useUnit(anchorClicked);
  const isActive = route ? useUnit(route.route.$isOpened) : () => false;

  const href = createMemo(() => {
    if (route) {
      return buildPath({ route, params: splited.params, query: splited.query });
    }

    if (typeof splited.to === 'string') {
      return splited.to;
    }

    return '';
  });

  return (
    <a
      link
      {...rest}
      href={href() || splited.href}
      classList={{
        ...(splited.class && { [splited.class]: true }),
        [props.inactiveClass!]: !isActive(),
        [props.activeClass!]: isActive(),
        ...rest.classList,
      }}
      onClick={(event) => {
        if (splited.whenClick) {
          splited.whenClick?.(event);
        }

        handleAnchorClick({
          event: event,
          anchor: event.currentTarget,
        });
      }}
      aria-current={isActive() ? 'page' : undefined}
    />
  );
}

export { A as Link, A as NavLink, AnchorProps as LinkProps, AnchorProps as NavLinkProps };
