import { MessageOptions } from '@lingui/core';
import { createMemo, JSX, ParentProps } from 'solid-js';

import { formatElements } from './format';
import { useLinguiInternal } from './provider';

type TransProps = ParentProps<{
  id: string;
  message?: string;
  values?: Record<string, unknown>;
  formats?: MessageOptions['formats'];
  components?: { [key: string]: JSX.Element };
}>;

export const Trans = (props: TransProps) => {
  let errMessage: string | undefined = undefined;

  if (process.env.NODE_ENV !== 'production') {
    errMessage = `Trans component was rendered without I18nProvider.`;
  }

  const lingui = useLinguiInternal(errMessage);

  const View = createMemo(() => {
    const components = { ...props.components };
    const values = { ...props.values };

    Object.keys(values).forEach((key) => {
      const value = values[key];
      const valueIsSolidEl =
        value instanceof Element ||
        (Array.isArray(value) && value.every((value) => value instanceof Element));

      if (!valueIsSolidEl) return;

      const index = Object.keys(components).length;

      components[index] = value;
      values[key] = `<${index}/>`;
    });

    const translation =
      lingui.i18n && typeof lingui._ === 'function'
        ? lingui._(props.id, values, {
            message: props.message,
            formats: props.formats,
          })
        : props.id;

    return translation ? formatElements(translation, components) : null;
  });

  return View;
};
