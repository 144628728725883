import { AxiosError } from 'axios';
import { createEffect } from 'effector';

type ErrorProps = { code: number; message?: string; errors?: string[] };

export class AppError {
  public code?: number;
  public message?: string;
  public errors?: string[];
  public readonly originalError: unknown;

  constructor(error: unknown) {
    this.originalError = error;
  }

  set({ code, message, errors }: ErrorProps) {
    if (code) {
      this.code = code;
    }

    if (message) {
      this.message = message;
    }

    if (errors?.length) {
      this.errors = errors;
    }
  }
}

export const errorHandler = (e: unknown) => {
  const error = new AppError(e);

  // ошибки с axios
  if (e instanceof AxiosError) {
    error.set({ code: e?.response?.status || 0 });
  }

  // стандартный инстанс респонса RESTful API
  else if (e instanceof Response) {
    error.set({ code: e.status });
  }

  // стандартная ошибка
  else if (e instanceof Error || e instanceof ErrorEvent) {
    error.set({ code: 0 });
  }

  // браузерная ошибка (обычно это ошибка сети или CORS)
  else if (e instanceof ProgressEvent) {
    error.set({ code: 0 });
  }

  // потенциально все прочие ошибки
  else {
    const err = e as any;
    error.set({ code: Number(err?.status) || 0 });
  }

  return error;
};

export const errorHandlerFx = createEffect(errorHandler);
