import { css } from '@linaria/core';

export const hovered = css`
  color: var(--color-red);
`;

export const root = css`
  display: grid;
  gap: 2rem;
`;

export const section = css`
  display: flex;
  flex-direction: column;
`;

export const sectionTitle = css`
  padding: 0 0.5rem;
  color: #000;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4;
`;

export const sectionSubtitle = css`
  padding: 0.5rem 1rem;
  padding-left: 3.8rem;
  color: #5d5d5f;
  font-size: 1.4rem;
  line-height: 1.4;
  text-transform: lowercase;
`;

export const list = css`
  display: grid;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const dataCell = css`
  grid-template-columns: max-content auto;
`;

export const listItem = css`
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const link = css`
  position: relative;
  display: block;
  width: auto;
  max-width: 100%;
  margin: 0;
  padding: 1rem;
  border: 0;
  border-radius: 0.5rem;
  background-color: transparent;
  outline: none;
  word-wrap: break-word;
  cursor: pointer;

  &:hover {
    background-color: var(--color-light-light-grey);
  }
`;

export const activeLink = css`
  color: var(--color-red);
`;

export const smile = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
`;
