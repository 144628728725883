import { trackMediaQuery } from '@withease/web-api';
import { createEvent } from 'effector';

import { createBreakPointsFromSizes, screenSizes } from '@shared/ui';

const start = createEvent();
const stop = createEvent();

const mediaQueries = createBreakPointsFromSizes({ ...screenSizes, isDesktop: screenSizes.sm });
const breakpoints = trackMediaQuery(mediaQueries, {
  setup: start,
  teardown: stop,
});

export const coreModel = {
  inputs: {
    start,
    stop,
  },
  outputs: {
    breakpoints,
  },
};
