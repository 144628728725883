export const PlatformLogo = () => {
  return (
    <svg
      width="163"
      height="34"
      viewBox="0 0 606 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M541.099 95.5516C537.501 95.5516 534.294 94.9274 531.479 93.6789C528.664 92.406 526.436 90.5333 524.796 88.061C523.18 85.5641 522.373 82.4552 522.373 78.7344C522.373 75.6011 522.948 72.9695 524.098 70.8399C525.249 68.7102 526.816 66.9966 528.798 65.6992C530.781 64.4018 533.033 63.4227 535.555 62.7617C538.101 62.1008 540.769 61.6357 543.559 61.3664C546.84 61.0237 549.483 60.7055 551.491 60.4117C553.498 60.0935 554.954 59.6284 555.86 59.0164C556.766 58.4044 557.219 57.4987 557.219 56.2992V56.0789C557.219 53.7534 556.484 51.9542 555.016 50.6813C553.571 49.4083 551.515 48.7719 548.847 48.7719C546.032 48.7719 543.792 49.3961 542.127 50.6445C540.463 51.8685 539.361 53.4107 538.823 55.2711L524.355 54.0961C525.09 50.669 526.534 47.707 528.688 45.2102C530.842 42.6888 533.621 40.755 537.023 39.4086C540.451 38.0378 544.416 37.3524 548.92 37.3524C552.054 37.3524 555.052 37.7195 557.916 38.4539C560.805 39.1883 563.363 40.3266 565.591 41.8688C567.843 43.4109 569.617 45.3938 570.915 47.8172C572.212 50.2162 572.861 53.0925 572.861 56.4461V94.4867H558.027V86.6656H557.586C556.68 88.4281 555.469 89.9826 553.951 91.3289C552.433 92.6508 550.609 93.6912 548.48 94.45C546.35 95.1844 543.89 95.5516 541.099 95.5516ZM545.579 84.7563C547.88 84.7563 549.912 84.3034 551.674 83.3977C553.437 82.4675 554.82 81.219 555.823 79.6524C556.827 78.0857 557.329 76.311 557.329 74.3281V68.343C556.839 68.6612 556.166 68.955 555.309 69.2242C554.477 69.469 553.535 69.7016 552.482 69.9219C551.429 70.1177 550.377 70.3013 549.324 70.4727C548.272 70.6195 547.317 70.7542 546.46 70.8766C544.624 71.1458 543.021 71.5742 541.65 72.1617C540.279 72.7492 539.214 73.5448 538.456 74.5485C537.697 75.5276 537.317 76.7516 537.317 78.2203C537.317 80.35 538.088 81.9779 539.63 83.1039C541.197 84.2055 543.18 84.7563 545.579 84.7563Z"
        fill="#141414"
      />
      <path
        d="M481.161 77.1922L496.436 38.0867H508.333L486.338 94.4867H475.947L454.466 38.0867H466.327L481.161 77.1922ZM462.765 38.0867V94.4867H447.82V38.0867H462.765ZM500.328 94.4867V38.0867H515.089V94.4867H500.328Z"
        fill="#141414"
      />
      <path
        d="M384.559 115.637V38.0867H399.981V47.5602H400.678C401.364 46.0425 402.355 44.5003 403.653 42.9336C404.974 41.3425 406.688 40.0206 408.793 38.968C410.923 37.8909 413.567 37.3524 416.724 37.3524C420.837 37.3524 424.631 38.4294 428.107 40.5836C431.583 42.7133 434.362 45.9323 436.442 50.2406C438.523 54.5245 439.564 59.8977 439.564 66.3602C439.564 72.6513 438.548 77.9633 436.516 82.2961C434.509 86.6044 431.767 89.8724 428.291 92.1C424.839 94.3031 420.972 95.4047 416.688 95.4047C413.652 95.4047 411.07 94.9029 408.94 93.8992C406.835 92.8956 405.109 91.6349 403.763 90.1172C402.416 88.575 401.388 87.0206 400.678 85.4539H400.201V115.637H384.559ZM399.871 66.2867C399.871 69.6404 400.336 72.5656 401.266 75.0625C402.196 77.5594 403.542 79.5055 405.305 80.9008C407.067 82.2716 409.209 82.957 411.731 82.957C414.277 82.957 416.431 82.2594 418.193 80.8641C419.956 79.4443 421.29 77.486 422.196 74.9891C423.126 72.4677 423.591 69.5669 423.591 66.2867C423.591 63.031 423.138 60.1669 422.232 57.6945C421.327 55.2221 419.992 53.2883 418.23 51.893C416.467 50.4977 414.301 49.8 411.731 49.8C409.185 49.8 407.031 50.4732 405.268 51.8195C403.53 53.1659 402.196 55.0753 401.266 57.5477C400.336 60.0201 399.871 62.9331 399.871 66.2867Z"
        fill="#141414"
      />
      <path
        d="M348.8 95.5883C343.096 95.5883 338.164 94.3766 334.002 91.9531C329.865 89.5052 326.671 86.1026 324.419 81.7453C322.166 77.3636 321.04 72.2841 321.04 66.507C321.04 60.681 322.166 55.5893 324.419 51.232C326.671 46.8503 329.865 43.4477 334.002 41.0242C338.164 38.5763 343.096 37.3524 348.8 37.3524C354.503 37.3524 359.424 38.5763 363.561 41.0242C367.722 43.4477 370.929 46.8503 373.181 51.232C375.433 55.5893 376.559 60.681 376.559 66.507C376.559 72.2841 375.433 77.3636 373.181 81.7453C370.929 86.1026 367.722 89.5052 363.561 91.9531C359.424 94.3766 354.503 95.5883 348.8 95.5883ZM348.873 83.4711C351.468 83.4711 353.634 82.7367 355.372 81.268C357.11 79.7748 358.42 77.743 359.301 75.1727C360.207 72.6024 360.66 69.6771 360.66 66.3969C360.66 63.1167 360.207 60.1914 359.301 57.6211C358.42 55.0508 357.11 53.019 355.372 51.5258C353.634 50.0326 351.468 49.2859 348.873 49.2859C346.254 49.2859 344.051 50.0326 342.264 51.5258C340.501 53.019 339.167 55.0508 338.261 57.6211C337.38 60.1914 336.94 63.1167 336.94 66.3969C336.94 69.6771 337.38 72.6024 338.261 75.1727C339.167 77.743 340.501 79.7748 342.264 81.268C344.051 82.7367 346.254 83.4711 348.873 83.4711Z"
        fill="#141414"
      />
      <path
        d="M270.383 113.654V19.2867H285.988V113.654H270.383ZM270.383 95.5883C266.05 95.5883 262.097 94.8906 258.523 93.4953C254.949 92.0755 251.864 90.0805 249.269 87.5102C246.675 84.9154 244.667 81.8433 243.248 78.2938C241.828 74.7198 241.118 70.7909 241.118 66.5071C241.118 62.1742 241.828 58.2331 243.248 54.6836C244.667 51.1097 246.675 48.0375 249.269 45.4672C251.864 42.8724 254.949 40.8774 258.523 39.4821C262.097 38.0623 266.05 37.3524 270.383 37.3524H273.283V95.5883H270.383ZM270.383 83.3977H272.108V49.543H270.383C268.278 49.543 266.405 49.9836 264.765 50.8649C263.125 51.7461 261.742 52.9578 260.616 54.5C259.489 56.0177 258.633 57.8047 258.045 59.861C257.482 61.9172 257.201 64.1326 257.201 66.5071C257.201 69.6404 257.715 72.4922 258.743 75.0625C259.771 77.6084 261.276 79.6401 263.259 81.1578C265.242 82.6511 267.617 83.3977 270.383 83.3977ZM285.988 95.5883H283.087V37.3524H285.988C290.321 37.3524 294.274 38.0623 297.848 39.4821C301.422 40.8774 304.507 42.8724 307.101 45.4672C309.696 48.0375 311.704 51.1097 313.123 54.6836C314.543 58.2331 315.253 62.1742 315.253 66.5071C315.253 70.7909 314.543 74.7198 313.123 78.2938C311.704 81.8433 309.696 84.9154 307.101 87.5102C304.507 90.0805 301.422 92.0755 297.848 93.4953C294.274 94.8906 290.321 95.5883 285.988 95.5883ZM285.988 83.3977C288.093 83.3977 289.966 82.9693 291.606 82.1125C293.246 81.2313 294.629 80.0196 295.755 78.4774C296.881 76.9352 297.738 75.1482 298.326 73.1164C298.913 71.0602 299.207 68.8571 299.207 66.5071C299.207 63.3492 298.681 60.4974 297.628 57.9516C296.6 55.3813 295.107 53.3373 293.148 51.8196C291.19 50.3018 288.803 49.543 285.988 49.543H284.262V83.3977H285.988Z"
        fill="#141414"
      />
      <path
        d="M186.99 50.3875V38.0867H238.103V50.3875H219.927V94.4867H205.019V50.3875H186.99Z"
        fill="#141414"
      />
      <path
        d="M150.928 95.5516C147.33 95.5516 144.123 94.9274 141.308 93.6789C138.493 92.406 136.265 90.5333 134.625 88.061C133.009 85.5641 132.202 82.4552 132.202 78.7344C132.202 75.6011 132.777 72.9695 133.927 70.8399C135.078 68.7102 136.645 66.9966 138.627 65.6992C140.61 64.4018 142.862 63.4227 145.384 62.7617C147.93 62.1008 150.598 61.6357 153.388 61.3664C156.669 61.0237 159.312 60.7055 161.32 60.4117C163.327 60.0935 164.783 59.6284 165.689 59.0164C166.595 58.4044 167.048 57.4987 167.048 56.2992V56.0789C167.048 53.7534 166.313 51.9542 164.845 50.6813C163.4 49.4083 161.344 48.7719 158.676 48.7719C155.861 48.7719 153.621 49.3961 151.956 50.6445C150.292 51.8685 149.19 53.4107 148.652 55.2711L134.184 54.0961C134.919 50.669 136.363 47.707 138.517 45.2102C140.671 42.6888 143.45 40.755 146.852 39.4086C150.28 38.0378 154.245 37.3524 158.749 37.3524C161.883 37.3524 164.881 37.7195 167.745 38.4539C170.634 39.1883 173.192 40.3266 175.42 41.8688C177.672 43.4109 179.446 45.3938 180.744 47.8172C182.041 50.2162 182.69 53.0925 182.69 56.4461V94.4867H167.856V86.6656H167.415C166.509 88.4281 165.297 89.9826 163.78 91.3289C162.262 92.6508 160.438 93.6912 158.309 94.45C156.179 95.1844 153.719 95.5516 150.928 95.5516ZM155.408 84.7563C157.709 84.7563 159.741 84.3034 161.503 83.3977C163.266 82.4675 164.649 81.219 165.652 79.6524C166.656 78.0857 167.158 76.311 167.158 74.3281V68.343C166.668 68.6612 165.995 68.955 165.138 69.2242C164.306 69.469 163.364 69.7016 162.311 69.9219C161.258 70.1177 160.206 70.3013 159.153 70.4727C158.101 70.6195 157.146 70.7542 156.289 70.8766C154.453 71.1458 152.85 71.5742 151.479 72.1617C150.108 72.7492 149.043 73.5448 148.284 74.5485C147.526 75.5276 147.146 76.7516 147.146 78.2203C147.146 80.35 147.917 81.9779 149.459 83.1039C151.026 84.2055 153.009 84.7563 155.408 84.7563Z"
        fill="#141414"
      />
      <path
        d="M71.2077 94.4867L71.1342 82.0758H73.0069C74.3287 82.0758 75.467 81.7576 76.4217 81.1211C77.4009 80.4602 78.2087 79.3953 78.8452 77.9266C79.4816 76.4578 79.9834 74.5117 80.3506 72.0883C80.7178 69.6404 80.9626 66.6294 81.085 63.0555L82.003 38.0867H124.89V94.4867H109.983V50.3875H96.0663L95.185 67.6086C94.9647 72.3331 94.3894 76.3966 93.4592 79.7992C92.5535 83.2018 91.2928 85.9925 89.6772 88.1711C88.0616 90.3253 86.1155 91.9164 83.8389 92.9445C81.5623 93.9727 78.9186 94.4867 75.9077 94.4867H71.2077Z"
        fill="#141414"
      />
      <path
        d="M61.8402 19.2867V94.4867H46.1245V32.2117H15.7214V94.4867H0.00579834V19.2867H61.8402Z"
        fill="#141414"
      />
      <path
        d="M593.925 27.4131H596.745C598.789 27.4131 600.495 27.8004 601.864 28.575C603.243 29.3495 604.279 30.3684 604.973 31.6317C605.667 32.8857 606.009 34.2366 606 35.6843C606.009 37.3164 605.609 38.7687 604.8 40.0412C603.999 41.3045 602.91 42.3003 601.531 43.0288C600.163 43.748 598.62 44.1076 596.904 44.1076C595.882 44.1076 594.875 43.9555 593.882 43.6512C592.898 43.3377 591.997 42.7752 591.178 41.9638L590.961 42.0468V35.297C590.97 36.1177 591.173 36.8692 591.568 37.5515C591.963 38.2339 592.527 38.7825 593.26 39.1975C594.002 39.6032 594.889 39.8061 595.921 39.8061C596.943 39.8061 597.825 39.6032 598.567 39.1975C599.319 38.7918 599.902 38.2523 600.317 37.5792C600.731 36.9061 600.934 36.1638 600.924 35.3524C600.934 34.504 600.726 33.7525 600.302 33.0978C599.878 32.4431 599.305 31.9268 598.581 31.5487C597.868 31.1707 597.053 30.9816 596.138 30.9816H593.925V27.4131ZM595.415 15.0755C596.735 15.0755 597.96 15.2645 599.088 15.6426C600.225 16.0206 601.213 16.5509 602.052 17.2332C602.9 17.9063 603.556 18.7039 604.019 19.626C604.491 20.5389 604.727 21.5394 604.727 22.6275C604.737 23.8999 604.404 25.1448 603.729 26.3619C603.064 27.5699 602.076 28.5703 600.765 29.3634C599.464 30.1471 597.858 30.539 595.95 30.539H593.925V27.1227H595.342C596.355 27.1227 597.174 26.929 597.801 26.5417C598.437 26.1452 598.904 25.6519 599.203 25.0618C599.502 24.4624 599.647 23.8584 599.637 23.2499C599.647 22.171 599.256 21.2812 598.466 20.5804C597.685 19.8704 596.668 19.5154 595.415 19.5154C594.585 19.5154 593.834 19.6814 593.159 20.0133C592.494 20.3453 591.963 20.8248 591.568 21.4518C591.173 22.0696 590.97 22.8073 590.961 23.6648V51.7564H585.87V23.4158C585.87 21.7469 586.29 20.2853 587.129 19.0313C587.977 17.7772 589.119 16.8044 590.556 16.1129C592.002 15.4213 593.621 15.0755 595.415 15.0755Z"
        fill="#141414"
      />
    </svg>
  );
};
