import { useUnit } from 'effector-solid';

import { registerPlatformModel } from '@shared/lib/composer';

import {
  $layoutType,
  $padding,
  $sidebarIsCollapsed,
  $sidebarIsOpened,
  setLayoutType,
  sidebarClosed,
  sidebarOpened,
  start,
} from './core';

export const layoutModel = registerPlatformModel({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start,
  },
  api: {
    outputs: {
      $layoutType,
      $sidebarIsOpened,
      $sidebarIsCollapsed,
    },
    inputs: {
      sidebarOpened,
      sidebarClosed,
      setLayoutType,
    },
    useModel() {
      return useUnit({
        padding: $padding,
        sidebarIsOpened: $sidebarIsOpened,
        sidebarIsCollapsed: $sidebarIsCollapsed,
        layoutType: $layoutType,
        sidebarOpened,
        sidebarClosed,
        setLayoutType,
      });
    },
  },
});
