import { MetaProvider } from '@solidjs/meta';
import { type Component } from 'solid-js';

export const withMeta = (WrappedComponent: Component) => (props) => {
  return (
    <MetaProvider>
      <WrappedComponent {...props} />
    </MetaProvider>
  );
};
