import { createContext, JSX, useContext } from 'solid-js';

import type { createRouter } from '../model/create-router';

type Router = ReturnType<typeof createRouter>;

export const Context = createContext<Router | null>(null);

export function Provider(props: { router: Router; children: JSX.Element }) {
  const state = {
    get context() {
      return props.router;
    },
  };

  return <Context.Provider value={state.context}>{props.children}</Context.Provider>;
}

export function useRouter() {
  return useContext(Context) as Router;
}
