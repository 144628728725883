import { css } from '@linaria/core';

export const notificationRoot = css`
  padding: 1.6rem 2rem;
  border-radius: 0.5rem;
  background-color: rgb(47 47 47 / 100%);
  color: white;
  font-size: 1.4rem;
  line-height: 1.2;
`;
