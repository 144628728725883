import { cx } from '@linaria/core';
import { Atom, createHover } from '@solid-kit/aria';
import { HTMLProps } from '@solid-kit/types';
import { JSX, splitProps } from 'solid-js';

import { ColumnOption, RowData } from '../table-general';
import { tableRow } from './index.css';

export type TableRowChildProps = {
  isHovered?: Atom<boolean>;
};

export function TableRow<R extends RowData>(
  props: Omit<HTMLProps<'div'>, 'children'> & {
    rowSize?: ColumnOption<R>[];
    children: ((props: TableRowChildProps) => JSX.Element) | JSX.Element;
  },
) {
  const [local, rest] = splitProps(props, ['rowSize', 'class']);

  const { isHovered, hoverProps } = createHover({});

  function getRowSize() {
    return local.rowSize
      ?.map((size) => `minmax(${size.minWidth || '10rem'},${size.flow || 1}fr)`)
      .join('');
  }

  return (
    <div
      class={cx(tableRow, local.class)}
      style={{ 'grid-template-columns': getRowSize() }}
      {...rest}
      {...hoverProps}
    >
      {typeof props.children === 'function'
        ? props.children({ isHovered: isHovered! })
        : props.children}
    </div>
  );
}
