import { createRule } from '@shared/lib/effector-form';
import { Yup } from '@shared/lib/validation';

export const deleteAccountFormModal = {
  password: {
    init: '',
    rules: [
      createRule({
        name: 'password',
        schema: Yup.string().required('Заполните поле'),
      }),
    ],
  },
  isSocialAuth: {
    init: false,
  },
};
