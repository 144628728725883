import { createBarrier } from '@shared/lib/barrier';
import { errorHandler } from '@shared/lib/error-handler';

const hasError = (error: unknown) => {
  return [401].includes(errorHandler(error).code as number);
};

const channelTokenBarrier = createBarrier({
  activateOn: {
    failure: ({ error }) => hasError(error),
  },
});

const jwtTokenBarrier = createBarrier({
  activateOn: {
    failure: ({ error }) => hasError(error),
  },
});

export { jwtTokenBarrier, channelTokenBarrier };
