import { css } from '@linaria/core';
import { styled } from '@solidex/linaria';

import { breakpointsCss } from '@shared/lib/hooks';

export const RootStyled = styled.div<{ padding?: string }>`
  display: grid;
  grid-template-areas: 'block-left block-middle block-right';
  grid-template-columns: calc(var(--sider-width) - var(--layout-root-padding)) 1fr auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0 var(--layout-root-padding);
  background-color: rgb(255 255 255 / 100%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 9%);
`;

export const block = css`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-strart;
  align-items: center;
`;

export const leftBlock = css`
  grid-area: block-left;
`;

export const middleBlock = css`
  display: flex;
  grid-area: block-middle;
  align-items: center;
  border-right: 1px solid var(--color-black-7);
  border-left: 1px solid var(--color-black-7);
`;

export const rightBlock = css`
  grid-area: block-right;
  padding-left: 3rem;
`;

export const toggler = css`
  display: flex;
  margin-right: 1rem;

  @media ${breakpointsCss.sm} {
    margin-right: 1rem;
  }
`;

export const rightContent = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
