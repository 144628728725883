import { HTMLProps } from '@solid-kit/types';
import { For, JSX } from 'solid-js';

import { TableCell } from '../table-cell';
import { ColumnOption, RowData } from '../table-general';
import { TableRow, TableRowChildProps } from '../table-row';
import { tableBody, tableBodyRow } from './index.css';

type TableBodyProps<T extends RowData> = {
  columnOptions: ColumnOption<T>[];
  rows: T[];
} & HTMLProps<'div'>;

export function TableBody<T extends RowData>(props: TableBodyProps<T>) {
  function getCellData(
    { renderContent, key }: ColumnOption<T>,
    rowData: T,
    rowProps: TableRowChildProps,
  ) {
    if (renderContent) return renderContent(rowData, { rowIsHovered: rowProps.isHovered });
    if (key) return rowData[key];

    return null;
  }

  function renderCell(rowData: T, rowProps: TableRowChildProps): JSX.Element[] {
    return props.columnOptions.map((columnOption) => (
      <TableCell align={columnOption.align} fixed={columnOption.fixed}>
        {getCellData(columnOption, rowData, rowProps)}
      </TableCell>
    ));
  }

  return (
    <div class={tableBody}>
      <For each={props.rows}>
        {(rowData) => (
          <TableRow rowSize={props.columnOptions} class={tableBodyRow}>
            {(rowProps) => renderCell(rowData, rowProps)}
          </TableRow>
        )}
      </For>
    </div>
  );
}
