import { Trans } from '@lingui/macro';
import { ic_m_clear } from '@rteam-fe/icons';
import { Icon, Stack } from '@solid-kit/ui';
import { Button, Dialog, Text } from '@solid-kit/ui/v2';
import { useUnit } from 'effector-solid';
import { onCleanup, Show } from 'solid-js';

import { type CodeVerifyModal, modalService } from '@shared/services/modals';

import { $isFinished, modalClosed } from '../../model';
import { CodeVerifyField } from '../code-verify-field';
import { styles } from './index.css';

export function CodeVerifyModal(props: CodeVerifyModal) {
  const { closeModal, unmounted, isFinished } = useUnit({
    isFinished: $isFinished,
    unmounted: modalClosed,
    closeModal: modalService.inputs.closeModal,
  });

  onCleanup(() => {
    unmounted();
  });

  return (
    <Dialog.Root
      open={true}
      scrollStrategy="inner"
      centered
      onExitComplete={() => {
        closeModal();
      }}
    >
      {(api) => (
        <Dialog.Portal>
          <Dialog.Backdrop />
          <Dialog.Positioner>
            <Dialog.Content class={styles.content}>
              <Dialog.Header class={styles.header}>
                <Text variant="header-2">
                  <Trans>Подтверждения</Trans>
                </Text>
                <Button
                  class={styles.closeButton}
                  leftIcon={<Icon source={ic_m_clear} />}
                  variant="text"
                  pin="circle-circle"
                  onClick={() => api().setOpen(false)}
                />
              </Dialog.Header>
              <Dialog.Body class={styles.body}>
                <Stack direction="column">
                  <Show
                    when={!isFinished()}
                    fallback={
                      <Text as="p" variant="body-2">
                        <Trans>Вы успешно сменили email адрес!</Trans>
                      </Text>
                    }
                  >
                    <Text as="p" variant="body-2">
                      <Trans>
                        На ваш номер <strong>{props.phone}</strong> отправлено сообщение с кодом для
                        подтверждения
                      </Trans>
                    </Text>
                    <CodeVerifyField />
                  </Show>
                </Stack>
              </Dialog.Body>
            </Dialog.Content>
          </Dialog.Positioner>
        </Dialog.Portal>
      )}
    </Dialog.Root>
  );
}
