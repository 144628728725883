import { css } from '@linaria/core';

export const styles = {
  closeButton: css`
    margin-right: -1rem;
  `,

  header: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    padding: 0 2rem;
  `,

  body: css`
    display: grid;
    padding: 0 2rem 2.5rem;
  `,

  content: css`
    z-index: var(--modal-z-index);
    width: 100%;
    max-width: 50rem;
  `,
};
