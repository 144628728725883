import type { MatchFilter, MatchFilters, PathMatch, Route } from '../types';

export function extractSearchParams(url: URL): Record<string, string> {
  const params: Record<string, string> = {};
  url.searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

export const clearQueryParams = (url: string, keys: string[]) => {
  const searchParams = new URLSearchParams(url.toString());

  for (const key of keys) {
    searchParams.delete(key);
  }

  return searchParams.toString();
};

export function createMatcher<S extends string>(
  path: S,
  partial?: boolean,
  matchFilters?: MatchFilters<S>,
) {
  const [pattern, splat] = path.split('/*', 2);
  const segments = pattern.split('/').filter(Boolean);
  const len = segments.length;

  return (location: string): PathMatch | null => {
    const locSegments = location.split('/').filter(Boolean);
    const lenDiff = locSegments.length - len;
    if (lenDiff < 0 || (lenDiff > 0 && splat === undefined && !partial)) {
      return null;
    }

    const match: PathMatch = {
      path: len ? '' : '/',
      params: {},
    };

    const matchFilter = (s: string) =>
      matchFilters === undefined ? undefined : (matchFilters as Record<string, MatchFilter>)[s];

    for (let i = 0; i < len; i++) {
      const segment = segments[i];
      const locSegment = locSegments[i];
      const dynamic = segment[0] === ':';
      const key = dynamic ? segment.slice(1) : segment;

      if (dynamic && matchSegment(locSegment, matchFilter(key))) {
        match.params[key] = locSegment;
      } else if (dynamic || !matchSegment(locSegment, segment)) {
        return null;
      }
      match.path += `/${locSegment}`;
    }

    if (splat) {
      const remainder = lenDiff ? locSegments.slice(-lenDiff).join('/') : '';
      if (matchSegment(remainder, matchFilter(splat))) {
        match.params[splat] = remainder;
      } else {
        return null;
      }
    }

    return match;
  };
}

function matchSegment(input: string, filter?: string | MatchFilter): boolean {
  const isEqual = (s: string) => s.localeCompare(input, undefined, { sensitivity: 'base' }) === 0;

  if (filter === undefined) {
    return true;
  } else if (typeof filter === 'string') {
    return isEqual(filter);
  } else if (typeof filter === 'function') {
    return (filter as Function)(input);
  } else if (Array.isArray(filter)) {
    return (filter as string[]).some(isEqual);
  } else if (filter instanceof RegExp) {
    return (filter as RegExp).test(input);
  }
  return false;
}

export function scoreRoute(route: Route): number {
  const [pattern, splat] = route.pattern.split('/*', 2);
  const segments = pattern.split('/').filter(Boolean);
  return segments.reduce(
    (score, segment) => score + (segment.startsWith(':') ? 2 : 3),
    segments.length - (splat === undefined ? 0 : 1),
  );
}

export function expandOptionals(pattern: string): string[] {
  let match = /(\/?\:[^\/]+)\?/.exec(pattern);
  if (!match) return [pattern];

  let prefix = pattern.slice(0, match.index);
  let suffix = pattern.slice(match.index + match[0].length);
  const prefixes: string[] = [prefix, (prefix += match[1])];

  while ((match = /^(\/\:[^\/]+)\?/.exec(suffix))) {
    prefixes.push((prefix += match[1]));
    suffix = suffix.slice(match[0].length);
  }

  return expandOptionals(suffix).reduce<string[]>(
    (results, expansion) => [...results, ...prefixes.map((p) => p + expansion)],
    [],
  );
}
