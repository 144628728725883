import { createMutation } from '@farfetched/core';
import { invoke } from '@withease/factories';
import { attach, createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';

import { createForm } from '@shared/lib/effector-form';
import { modalService } from '@shared/services/modals';

import { resendCodeFx } from '../api';
import { codeVerifyFormConfig } from './config';
import { createTimer } from './timer';

const $isFinished = createStore(false);
const $serverError = createStore('');
const $$timer = invoke(createTimer, 60);
const codeVerifyForm = createForm({ fields: codeVerifyFormConfig });
const resendCodeMutation = createMutation({
  effect: attach({
    source: {
      codeId: codeVerifyForm.fields.codeId.$value,
    },
    mapParams: (_: void, { codeId }) => {
      return codeId;
    },
    effect: resendCodeFx,
  }),
});

const $currentModal = modalService.outputs.$activeModals.map(
  (activeModals) => activeModals.find((i) => i.name === 'code-verify') ?? null,
);

const modalOpened = sample({ source: $currentModal, filter: Boolean });
const modalClosed = createEvent();
const completed = createEvent();
const addError = createEvent<string>();
const finished = {
  success: createEvent(),
};

$serverError.on(addError, (_, msg) => msg).on([modalClosed, modalOpened], () => '');
$isFinished.on(finished.success, () => true).on([modalClosed, modalOpened], () => false);

sample({
  clock: resendCodeMutation.started,
  target: $$timer.stop,
});

sample({
  clock: [
    modalOpened,
    resendCodeMutation.finished.success.map(({ result }) => ({ codeId: result ?? '' })),
  ],
  //@ts-ignore
  fn: ({ codeId }) => ({ codeId: codeId }),
  target: [
    $$timer.reset,
    spread({
      codeId: codeVerifyForm.fields.codeId.setInitial,
    }),
    $$timer.start,
  ],
});

sample({
  clock: codeVerifyForm.submit,
  filter: codeVerifyForm.$isValid,
  target: completed,
});

sample({
  clock: [modalClosed, finished.success],
  target: [$$timer.stop, resendCodeMutation.reset, codeVerifyForm.reset],
});

export {
  codeVerifyForm,
  modalClosed,
  resendCodeMutation,
  $$timer,
  completed,
  $serverError,
  addError,
  finished,
  $isFinished,
};
