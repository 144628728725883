import { createRoute } from './model/create-route';
import type { RouteDefinition } from './types';

export const routes = {
  layout: createRoute(),
  index: createRoute(),
  profile: createRoute(),
  sessions: createRoute(),
  oauth: {
    login: createRoute(),
    register: createRoute(),
    restore: createRoute(),
  },
  notFound: createRoute(),
  cabinet: createRoute(),
};

export const routesMap: RouteDefinition[] = [
  {
    path: '/',
    route: routes.layout,
    children: [
      { path: '/', route: routes.index },
      { path: '/user/profile/', route: routes.profile },
      { path: '/user/sessions/', route: routes.sessions },
    ],
  },
  { path: '/user/login/', route: routes.oauth.login },
  { path: '/user/registration/', route: routes.oauth.register },
  { path: '/user/restore', route: routes.oauth.restore },
  { path: '*', route: routes.notFound },
];
