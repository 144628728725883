import { idFrontendApi } from '@shared/api';
import { MeDeleteRequest } from '@shared/api/id-frontend-api';
import { apiService } from '@shared/services/api';
import { AppLanguage } from '@shared/services/locale/languages';

import { RTeamIDGetProfileMe, RTeamIDPatchProfileMe } from './repo';

export const getProfileMeFx = apiService.inputs.createApiRequest({
  modificators: [apiService.inputs.withJWT],
  handler: async (input: { lang: AppLanguage }, options) => {
    try {
      const data = await idFrontendApi.getFrontendApiV1ProfileMeShow(input, options);
      return RTeamIDGetProfileMe.getOutput(data);
    } catch (e) {
      return RTeamIDGetProfileMe.toKnownErr(e);
    }
  },
});

export const patchProfileMeFx = apiService.inputs.createApiRequest({
  modificators: [apiService.inputs.withJWT],
  handler: async (input: RTeamIDPatchProfileMe.InputSchema & { lang: AppLanguage }, options) => {
    try {
      await idFrontendApi.patchFrontendApiV1ProfileMeUpdate(
        RTeamIDPatchProfileMe.getInput(input),
        { lang: input.lang },
        options,
      );
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
});

export const postEmailVerifiedFx = apiService.inputs.createApiRequest({
  modificators: [apiService.inputs.withJWT],
  handler: async (input: { lang: AppLanguage }, options) => {
    try {
      await idFrontendApi.postFrontendApiV1ProfileMeEmailVerifiedSend(input, options);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
});

export const deleteAccountFx = apiService.inputs.createApiRequest({
  modificators: [apiService.inputs.withJWT],
  handler: async (input: MeDeleteRequest & { lang: AppLanguage }, options) => {
    try {
      await idFrontendApi.deleteFrontendApiV1ProfileMeDelete(input, { lang: input.lang }, options);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
});
