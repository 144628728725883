import { ic_m_activity, ic_m_user_circle } from '@rteam-fe/icons';

import type { MenuOptionGroup } from '@shared/ui/platform-menu';

export const mainMenuMap = (): MenuOptionGroup[] => {
  return [
    {
      items: [
        {
          content: 'Личная информация',
          icon: ic_m_user_circle,
          href: '/user/profile/',
        },
        {
          content: 'Управление сессиями',
          icon: ic_m_activity,
          href: '/user/sessions/',
        },
      ],
    },
  ];
};
