import { For } from 'solid-js';

import { TableCell } from '../table-cell';
import { ColumnOption, RowData } from '../table-general';
import { TableRow } from '../table-row';
import { ellipsised, tableHead } from './index.css';

type TableHeadProps<T extends RowData> = {
  columnOptions: ColumnOption<T>[];
};

export function TableHead<T extends RowData>(props: TableHeadProps<T>) {
  function getHeader({ title, renderHeader }: ColumnOption<T>) {
    if (renderHeader) return renderHeader();

    return title;
  }

  return (
    <div class={tableHead}>
      <TableRow rowSize={props.columnOptions}>
        <For each={props.columnOptions}>
          {(columnOption) => (
            <TableCell align={columnOption.align} fixed={columnOption.fixed}>
              <span class={ellipsised}>{getHeader(columnOption)}</span>
            </TableCell>
          )}
        </For>
      </TableRow>
    </div>
  );
}
