import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  sample,
  scopeBind,
  split,
} from 'effector';
import isEqual from 'lodash.isequal';
import { and, not } from 'patronum';

import { type HistoryLocation, type HistoryState, type RouterHistory } from '../lib/history';
import { getPagePath, navigateToPath } from '../lib/path';
import { createBranches, getRouteMatches } from '../lib/routing';
import { clearQueryParams } from '../lib/utils';
import type { Navigate, Recalculated, Route, RouteDefinition, RouteQuery } from '../types';

type LocationState = {
  previousUrl?: string | null | undefined;
} & HistoryState;

type Update = {
  location: HistoryLocation;
};

export function createRouter({ routes }: { routes: RouteDefinition[] }) {
  const branches = createBranches(routes);
  const routesMap = branches
    .map(({ routes }) => routes)
    .flat()
    .reduce<Route[]>((acc, route) => {
      if (!acc.includes(route)) {
        acc.push(route);
      }

      return acc;
    }, []);

  const setHistory = createEvent<RouterHistory>();
  const navigateTo = createEvent<Navigate>();
  const historyUpdated = createEvent<Update>();
  const removeQueryParams = createEvent<{ keys: string[] }>();
  const replaceQueryParams = createEvent<{ search: string }>();
  const reloadPage = createEvent();
  const recalculatedTrigger = createEvent<HistoryLocation>();
  const recalculated = createEvent<Recalculated>();
  const routeNotFound = createEvent();
  const back = createEvent();
  const forward = createEvent();

  const windowOpenFx = createEffect<{ to: string; target?: string }, void>(({ to, target }) => {
    window.open(to, target);
  });

  const reloadPageFx = createEffect(() => {
    window?.location.reload();
  });

  const openNewTab = windowOpenFx.prepend((to: string) => ({ to, target: '_blank' }));
  const reload = windowOpenFx.prepend((to: string) => ({ to, target: '_self' }));

  const $history = createStore<RouterHistory | null>(null, { serialize: 'ignore' });
  const $isNavigationTriggered = createStore(false);
  const $historyEnv = createStore<Update | null>(null);
  const $pathname = createStore<string>('');
  const $search = createStore<string>('');
  const $query = createStore<RouteQuery>({});
  const $href = combine($pathname, $search, (pathname, search) => `${pathname}${search}`);
  const $navigateInProgress = createStore(false);
  const $activeRoutes = createStore<Route[]>([]);
  const $isReady = $history.map(Boolean);
  const $prevHref = createStore<any>(null);

  $history.on(setHistory, (_, history) => history);

  const pushFx = attach({
    source: {
      history: $history,
      href: $href,
    },
    effect: ({ history, href }, to: string) => {
      if (href !== to) {
        history?.push(to, {
          previousUrl: window.location.pathname + window.location.search,
        });
      }
    },
  });

  const replaceFx = attach({
    source: $history,
    effect: (history, to: string) => {
      history?.replace(to, {
        previousUrl: window.location.pathname + window.location.search,
      });
    },
  });

  const subscribeHistoryFx = attach({
    source: $history,
    effect: (history) => {
      let scopedHistoryUpdated: (payload: Update) => void;

      try {
        scopedHistoryUpdated = scopeBind(historyUpdated, { safe: true });
      } catch (err) {
        scopedHistoryUpdated = historyUpdated;
      }

      return history?.subscribe(() => {
        scopedHistoryUpdated({
          location: history.location,
        });
      });
    },
  });

  const backFx = attach({
    source: $history,
    effect: (history) => {
      history?.back();
    },
  });

  const forwardFx = attach({
    source: $history,
    effect: (history) => {
      history?.forward();
    },
  });

  sample({
    clock: $history.updates,
    filter: Boolean,
    target: subscribeHistoryFx,
  });

  sample({
    clock: $history.updates,
    filter: Boolean,
    fn: (history) => ({ location: history.location }),
    target: historyUpdated,
  });

  split({
    source: navigateTo,
    match: ({ type }) => type ?? 'push',
    cases: {
      push: pushFx.prepend<Navigate>(navigateToPath),
      reload: reload.prepend<Navigate>(navigateToPath),
      replace: replaceFx.prepend<Navigate>(navigateToPath),
      openTab: openNewTab.prepend<Navigate>(navigateToPath),
    },
  });

  sample({
    clock: navigateTo,
    fn: () => true,
    target: $isNavigationTriggered,
  });

  sample({
    clock: removeQueryParams,
    source: {
      pathname: $pathname,
      search: $search,
    },
    fn: ({ pathname, search }, { keys, ...rest }) => {
      return {
        pathname: pathname,
        search: clearQueryParams(search ?? '', keys),
        ...rest,
      };
    },
    target: navigateTo,
  });

  sample({
    clock: replaceQueryParams,
    source: $pathname,
    fn: (pathname, { search, ...rest }) => {
      return {
        pathname,
        search,
        ...rest,
      };
    },
    target: navigateTo,
  });

  sample({
    clock: reloadPage,
    target: reloadPageFx,
  });

  sample({
    clock: historyUpdated,
    fn: ({ location }) => location,
    target: recalculatedTrigger,
  });

  sample({
    clock: historyUpdated,
    fn: ({ location }) => {
      const state: LocationState = location.state || {};
      return state.previousUrl ? state.previousUrl : null;
    },
    target: $prevHref,
  });

  sample({
    clock: recalculatedTrigger,
    fn: (location) => {
      const matches = getRouteMatches(branches, location.pathname);
      const params = matches.reduce((acc, match) => Object.assign(acc, match.params), {});
      const query = Object.fromEntries(new URLSearchParams(location.search));

      return { params, query, matches };
    },
    target: recalculated,
  });

  const { routesMatched, routesMismatched } = split(recalculated, {
    routesMatched: ({ matches }) => matches.length > 0,
    routesMismatched: ({ matches }) => matches.length === 0,
  });

  $historyEnv.on(historyUpdated, (_, env) => env);
  $pathname.on($historyEnv, (_, history) => history?.location.pathname ?? '');
  $search.on($historyEnv, (_, history) => history?.location.search ?? '');
  $query.on($search, (_, search) => Object.fromEntries(new URLSearchParams(search)));
  $navigateInProgress.on(historyUpdated, () => true).reset([routesMatched, routesMismatched]);
  $activeRoutes.on(recalculated, (_, { matches }) => matches.map(({ route }) => route));

  // -- bind routes config
  for (const { route, pattern } of routesMap) {
    const { opened, closed } = split(recalculated, {
      opened: ({ matches }) => matches.some((match) => match.route.route === route),
      closed: ({ matches }) => matches.every((match) => match.route.route !== route),
    });

    sample({
      clock: opened,
      filter: and(not(route.$isOpened), not($navigateInProgress)),
      fn: ({ params, query }) => ({ params, query }),
      target: route.opened,
    });

    sample({
      clock: opened,
      source: [route.$params, route.$query, route.$isOpened],
      filter: ([params, query, isOpened], next) => {
        return isOpened && (!isEqual(params, next.params) || !isEqual(query, next.query));
      },
      fn: (_, { params, query }) => ({ params, query }),
      target: route.updated,
    });

    sample({
      clock: closed,
      filter: route.$isOpened,
      target: route.closed,
    });

    sample({
      clock: route.navigate.doneData,
      fn: ({ params, query, replace }) => {
        return {
          pathname: getPagePath(pattern, params),
          search: new URLSearchParams(query).toString(),
          type: replace ? 'replace' : 'push',
        } as const;
      },
      target: navigateTo,
    });
  }

  sample({
    clock: routesMismatched,
    target: routeNotFound,
  });

  sample({
    clock: back,
    target: backFx,
  });

  sample({
    clock: forward,
    target: forwardFx,
  });

  return {
    $href,
    $query,
    $search,
    $isReady,
    $history,
    $pathname,
    $prevHref,
    $activeRoutes,
    $isNavigationTriggered,
    back,
    forward,
    routesMap,
    navigateTo,
    reloadPage,
    openNewTab,
    routeNotFound,
    removeQueryParams,
    replaceQueryParams,
    setHistory,
    historyUpdated,
  };
}
