import { css } from '@linaria/core';

export const tableRow = css`
  position: relative;
  display: grid;
  grid-template-columns:
    minmax(40rem, 4fr) minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr) minmax(10rem, 1fr)
    minmax(10rem, 1fr) minmax(10rem, 1fr);
  grid-column-gap: 2rem;
  width: 100%;
  padding: 0 1rem;
`;

export const ellipsised = css`
  display: block;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
