import {
  attach,
  createEffect,
  createEvent,
  createStore,
  type EventCallable,
  fork,
  sample,
  scopeBind,
} from 'effector';
import { debounce } from 'patronum';

import { readonly } from '@shared/lib/patronus';

enum AppType {
  PLATFORM = 'platform',
  PRODUCT = 'product',
}

type RegisterModel = {
  name: string;
  type: AppType;
  service: {
    start?: EventCallable<void>;
    stop?: EventCallable<void>;
  };
};

type Model<T> = {
  importMetaUrl: string;
  lifecycle?: {
    start?: EventCallable<void>;
    stop?: EventCallable<void>;
  };
  api: T;
};

const scope = fork();

function getModelMeta(path: string) {
  try {
    const matches = path.match(/.*\/spa\/(?<name>.*?)\/index/);
    const { name } = matches?.groups as { name: string };

    return { name };
  } catch (error) {
    if (error) throw Error(`bad path: ${path}`);
  }

  return null;
}

function log(messages: string | string[]) {
  console.log(...['%c composer ', 'background:#ffdd2d;'].concat(messages));
}

const registeredModels = {
  [AppType.PLATFORM]: new Map<RegisterModel['name'], RegisterModel['service']>(),
  [AppType.PRODUCT]: new Map<RegisterModel['name'], RegisterModel['service']>(),
};

const preparedModels = {
  [AppType.PLATFORM]: new Map<RegisterModel['name'], RegisterModel['service']>(),
  [AppType.PRODUCT]: new Map<RegisterModel['name'], RegisterModel['service']>(),
};

let fallback = () => {};

const prepareModelsFx = createEffect(() => {
  registeredModels.platform.forEach((service, name) => {
    preparedModels.platform.set(name, service);
  });

  registeredModels.product.forEach((service, name) => {
    preparedModels.product.set(name, service);
  });
});

const startServicesFx = createEffect<{ type: AppType }, void>(async (model) => {
  const services = preparedModels[model.type];

  if (services.size) {
    log([model.type, 'start']);
  }

  services.forEach(async (lifecycle, name) => {
    try {
      lifecycle.start && lifecycle.start();
      log([`start`, name]);
    } catch (error) {
      console.log({ name, error });
    }
  });

  if (services.size) {
    log([model.type, 'done']);
  }

  services.clear();
});

const startPlatformServicesFx = attach({
  mapParams: () => {
    return { type: AppType.PLATFORM };
  },
  effect: startServicesFx,
});

const startProductServicesFx = attach({
  mapParams: () => {
    return { type: AppType.PRODUCT };
  },
  effect: startServicesFx,
});

const startPlatformServicesAttachFx = attach({ effect: startPlatformServicesFx });
const startProductServicesAttachFx = attach({ effect: startProductServicesFx });

const asyncLoadStart = createEvent();
const startApp = createEvent();
const stopApp = createEvent();
const restartApp = createEvent();

const platfromAllStarted = createEvent();
const productAllStarted = createEvent();

const appInited = readonly(platfromAllStarted);
const appStarted = readonly(productAllStarted);
const appStopped = createEvent();

const $appStarted = createStore(false)
  .on(appStarted, () => true)
  .on(appStopped, () => false);

sample({
  clock: startApp,
  target: prepareModelsFx,
});

sample({
  clock: startApp,
  target: startPlatformServicesAttachFx,
});

sample({
  clock: stopApp,
  target: appStopped,
});

sample({
  clock: restartApp,
  target: [stopApp, startApp],
});

sample({
  clock: startPlatformServicesAttachFx.done,
  target: platfromAllStarted,
});

sample({
  clock: platfromAllStarted,
  target: startProductServicesAttachFx,
});

sample({
  clock: startProductServicesAttachFx.done,
  target: productAllStarted,
});

const asyncLoadEnabledFx = createEffect(async () => {
  fallback = scopeBind(asyncLoadStart);
  console.log('async load enabled');
});

const asyncLoadFx = createEffect(async () => {
  await startPlatformServicesFx();
  await startProductServicesFx();
});

sample({
  clock: appStarted,
  target: asyncLoadEnabledFx,
});

sample({
  clock: debounce({
    source: asyncLoadStart,
    timeout: 0,
  }),
  target: asyncLoadFx,
});

function registerModel(model: RegisterModel) {
  registeredModels[model.type].set(model.name, model.service);
  preparedModels[model.type].set(model.name, model.service);

  if (model.service.stop) {
    sample({
      clock: appStopped,
      target: model.service.stop,
    });
  }

  fallback();
}

function registerPlatformModel<T>(model: Model<T>) {
  if (!model.importMetaUrl) {
    throw Error('Добавь в конфиг: `importMetaUrl: import.meta.url`');
  }

  const name = getModelMeta(model.importMetaUrl)?.name;

  if (!name || !model.importMetaUrl.endsWith('index.ts')) {
    throw Error('Модель должен быть объявлен в корне - model/index.ts');
  }

  if (model.lifecycle) {
    registerModel({
      type: AppType.PLATFORM,
      service: model.lifecycle,
      name,
    });
  }

  return {
    ...model.api,
    meta: {
      type: AppType.PLATFORM,
      name,
    },
  };
}

function registerPlatformService<T>(model: Model<T>) {
  if (!model.importMetaUrl) {
    throw Error('Добавь в конфиг: `importMetaUrl: import.meta.url`');
  }

  const name = getModelMeta(model.importMetaUrl)?.name;

  if (!name || !model.importMetaUrl.endsWith('index.ts')) {
    throw Error('Модель должен быть объявлен в корне - model/index.ts');
  }

  if (model.lifecycle) {
    registerModel({
      type: AppType.PLATFORM,
      service: model.lifecycle,
      name,
    });
  }

  return {
    ...model.api,
    meta: {
      type: AppType.PLATFORM,
      name,
    },
  };
}

function registerProductModel<T>(model: Model<T>) {
  console.log(model.importMetaUrl);
  if (!model.importMetaUrl) {
    throw Error('Добавь в конфиг: `importMetaUrl: import.meta.url`');
  }

  const name = getModelMeta(model.importMetaUrl)?.name;

  if (!name || !model.importMetaUrl.endsWith('index.ts')) {
    throw Error('Модель должен быть объявлен в корне - src/{slice}/index.ts');
  }

  if (model.lifecycle) {
    registerModel({
      type: AppType.PRODUCT,
      service: model.lifecycle,
      name,
    });
  }

  return {
    ...model.api,
    meta: {
      type: AppType.PRODUCT,
      name,
    },
  };
}

const composerModel = {
  outputs: {
    appInited,
    appStarted,
    appStopped,
    $appStarted,
  },
  inputs: {
    startApp,
    stopApp,
    restartApp,
  },
};

export {
  registerPlatformModel,
  registerProductModel,
  registerPlatformService,
  composerModel,
  scope,
};
