import { Component, createComponent, JSX, ParentProps, Show } from 'solid-js';

import type { RouteInstance, RouteParams } from '../types';
import { createIsOpened } from './create-is-opened';

type RouteProps<Params extends RouteParams> = ParentProps<
  {
    route: RouteInstance<Params> | RouteInstance<Params>[];
    load?: () => void;
  } & (
    | {
        element?: never;
        view: Component;
      }
    | {
        view?: never;
        element?: JSX.Element;
      }
  )
>;

export const Route = <Params extends RouteParams>(props: RouteProps<Params>) => {
  const isOpened = createIsOpened(() => props.route);
  const fallback = () => props.children;
  const View = () => {
    props.load && props.load();

    return props.view
      ? createComponent(props.view, props)
      : props.element === undefined && fallback
        ? createComponent(fallback, props)
        : props.element;
  };

  return <Show when={isOpened()} keyed children={<View />} />;
};
