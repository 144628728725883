import { attach, createStore, sample, scopeBind } from 'effector';
import { type MountableElement, render } from 'solid-js/web';

import { composerModel, scope } from '@shared/lib/composer';
import { viewerService } from '@shared/services/viewer';

import { Application } from './ui';

const $dispose = createStore<(() => void) | null>(null);
const $root = createStore<MountableElement>(document.querySelector('#root') as Element);

const appRenderFx = attach({
  source: $root,
  effect: (root) => {
    try {
      if (!root) {
        throw Error('Элемент для монтирования не найден');
      }

      return render(() => <Application />, root);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
});

const appDisposeFx = attach({
  source: $dispose,
  effect: (dispose) => {
    try {
      dispose?.();
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
});

$dispose.on(appRenderFx.doneData, (_, dispose) => dispose);
$dispose.on(appDisposeFx.doneData, () => null);

sample({ clock: viewerService.outputs.appInited, target: appRenderFx });
sample({ clock: viewerService.outputs.appStopped, target: appDisposeFx });

export const appService = {
  inputs: {
    start: scopeBind(composerModel.inputs.startApp, { scope }),
  },
};
