import { useUnit } from 'effector-solid';

import { registerPlatformModel } from '@shared/lib/composer';

import * as core from './core';

export const notificationModel = registerPlatformModel({
  importMetaUrl: import.meta.url,
  api: {
    inputs: {
      showSuccessFx: core.showSuccessFx,
      showErrorFx: core.showErrorFx,
    },
    useModel() {
      return {
        success: useUnit(core.showSuccessFx),
        error: useUnit(core.showErrorFx),
      };
    },
  },
});
