import { createFactory } from '@withease/factories';
import { createApi, createEvent, createStore, sample } from 'effector';
import { interval } from 'patronum';

export const createTimer = createFactory((time: number) => {
  const start = createEvent();
  const stop = createEvent();
  const reset = createEvent();

  const $started = createStore(false);
  const $time = createStore(time);
  const timeApi = createApi($time, {
    set: (state) => state - 1,
    reset: () => time,
    stop: () => 0,
  });

  const { tick } = interval({
    timeout: 1000,
    start: start,
    stop: stop,
  });

  sample({
    clock: tick,
    target: timeApi.set,
  });

  sample({
    clock: reset,
    target: timeApi.reset,
  });

  sample({
    clock: stop,
    target: timeApi.stop,
  });

  sample({
    clock: tick,
    source: $time,
    filter: (time) => time === 0,
    target: stop,
  });

  $started.on(start, () => true).on(stop, () => false);

  return {
    start,
    stop,
    reset,
    $time,
  };
});
