import { Fragment } from '@solid-kit/ui';
import { useUnit } from 'effector-solid';
import { Show } from 'solid-js';

import { BaseLayout } from '@app/layouts';

import { profilePage } from '@pages/profile';

import { sessionService } from '@services/session';

import { lazily } from '@shared/lib/lazily';
import { Navigate, Route, routes } from '@shared/services/router';

const { LoginPage } = lazily(() => import('@pages/login'));
const { RegistrationPage } = lazily(() => import('@pages/registration'));
const { RestorePage } = lazily(() => import('@pages/restore'));

const { SessionsPage } = lazily(() => import('@pages/sessions'));
const { ErrorPage } = lazily(() => import('@pages/error'));

export const RoutesContainer = () => {
  const { isAuthorized, hasProfile } = useUnit({
    isAuthorized: sessionService.outputs.$isAuthorized,
    hasProfile: sessionService.outputs.$profile.map(Boolean),
  });

  return (
    <Fragment>
      <Show
        when={isAuthorized() && hasProfile()}
        fallback={
          <>
            <Route route={routes.oauth.login} view={LoginPage} />
            <Route route={routes.oauth.register} view={RegistrationPage} />
            <Route route={routes.oauth.restore} view={RestorePage} />
            <Route route={routes.notFound} view={ErrorPage} />
          </>
        }
      >
        <Route route={routes.layout} view={BaseLayout}>
          <Route route={routes.index} element={<Navigate to={routes.profile} />} />
          <Route route={profilePage.route} view={profilePage.view} load={profilePage.load} />
          <Route route={routes.sessions} view={SessionsPage} />
          <Route route={routes.notFound} view={ErrorPage} />
        </Route>
      </Show>
    </Fragment>
  );
};
