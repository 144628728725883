import { createQuery } from '@farfetched/core';
import { attach, createEffect, createEvent, createStore, sample } from 'effector';

import { getProfileMeFx } from '@entities/profile';

import { apiService } from '@shared/services/api';
import { localeService } from '@shared/services/locale';
import { AppLanguage } from '@shared/services/locale/languages';
import { sanitizeAppLanguageSetting } from '@shared/services/locale/lib/helpers';

type SessionConfig = {
  isUserLoggedIn: boolean;
  user?: {
    session?: {
      apiToken?: string;
    };
  };
};

declare global {
  interface Window {
    __APP_CONFIG__?: SessionConfig;
  }
}

const profileQuery = createQuery({
  effect: attach({
    source: {
      lang: localeService.outputs.$appLanguage,
    },
    mapParams: (_, { lang }) => ({
      lang: sanitizeAppLanguageSetting(lang) ?? AppLanguage.ru,
    }),
    effect: getProfileMeFx,
  }),
});

const start = createEvent();
const stop = createEvent();

const $appConfig = createStore<SessionConfig | null>(null);

const getAppConfigFx = createEffect((): SessionConfig | null => {
  return window.__APP_CONFIG__ ?? null;
});

const $isAuthorized = $appConfig.map((t) => t?.isUserLoggedIn || false);
const $isSucceeded = profileQuery.$succeeded;
const $isFailed = profileQuery.$failed;

const authorized = sample({
  source: $appConfig,
  filter: (config) => Boolean(config && config.isUserLoggedIn),
});

sample({
  clock: start,
  target: getAppConfigFx,
});

sample({
  clock: getAppConfigFx.doneData,
  target: $appConfig,
});

sample({
  clock: authorized,
  fn: (config) => config?.user?.session?.apiToken || null,
  target: [apiService.internals.fillJwt, apiService.experiments.fillUserToken, profileQuery.start],
});

export const sessionModel = {
  inputs: {
    start,
    stop,
  },
  outputs: {
    authorized,
    $isAuthorized,
    $isSucceeded,
    $isFailed,
    $profile: profileQuery.$data,
  },
  queries: {
    profileQuery,
  },
};
