import { AppLanguage } from '../languages';

export function sanitizeAppLanguageSetting(appLanguage: string): AppLanguage {
  const langs = appLanguage.split(',').filter(Boolean);
  for (const lang of langs) {
    switch (lang) {
      case 'ru':
      case 'ru-RU':
        return AppLanguage.ru;

      case 'en':
      case 'en-US':
        return AppLanguage.en;
      default:
        continue;
    }
  }

  return AppLanguage.ru;
}
