/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 */
import { createEvent } from 'effector';

import { Setupable, setupListener } from './shared';
import { TriggerProtocol } from './trigger_protocol';

type PageBeforeUnload = ({ setup, teardown }: Setupable) => void;

function listener(event: any) {
  event.preventDefault();
  event.returnValue = '';

  return;
}

const trackPageBeforeUnload: PageBeforeUnload & TriggerProtocol = (config) => {
  setupListener(
    {
      add: () => window.addEventListener('beforeunload', listener, { capture: true }),
      remove: () => window.removeEventListener('beforeunload', listener, { capture: true }),
    },
    config,
  );
};

trackPageBeforeUnload['@@trigger'] = () => {
  const setup = createEvent();
  const teardown = createEvent();

  trackPageBeforeUnload({ setup, teardown });

  return { setup, teardown };
};

export { trackPageBeforeUnload };
