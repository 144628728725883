import { registerPlatformService } from '@shared/lib/composer';

import { coreModel } from './model';

export type * from './types';

export const modalService = registerPlatformService({
  importMetaUrl: import.meta.url,
  api: {
    inputs: {
      openModal: coreModel.inputs.modalControls.openModal,
      closeModal: coreModel.inputs.modalControls.closeModal,
      clearAllModals: coreModel.inputs.modalControls.clearAllModals,
    },
    outputs: {
      $activeModals: coreModel.outputs.$activeModals,
      $isModalActive: coreModel.outputs.$isModalActive,
    },
  },
});
