import { idFrontendApi } from '@shared/api';
import { apiService } from '@shared/services/api';

export const resendCodeFx = apiService.inputs.createApiRequest({
  modificators: [],
  barriers: [],
  handler: async (data: string) => {
    try {
      const { codeId } = await idFrontendApi.postFrontendApiV1AuthAuthCodeResend(data);
      return codeId;
    } catch (error) {
      console.error('Resend code failed', error);
      throw error;
    }
  },
});
