import { ParentProps } from 'solid-js';

import { fixed, tableCell } from './index.css';

type TableCellProps = ParentProps & {
  fixed?: boolean;
  align?: 'left' | 'center' | 'right';
};

export function TableCell(props: TableCellProps) {
  function getAlign() {
    return props.align ?? 'center';
  }

  return (
    <div
      class={tableCell}
      style={{ 'justify-content': getAlign() }}
      classList={{ [fixed]: props.fixed }}
    >
      {props.children}
    </div>
  );
}
