import { type I18n, MessageDescriptor } from '@lingui/core';
import {
  attach,
  combine,
  createEffect,
  createEvent,
  createStore,
  type Event,
  is,
  sample,
  scopeBind,
  type Store,
} from 'effector';

interface Translated {
  (descriptor: MessageDescriptor): Store<string>;
}

type LinguiIntegration = {
  $t: Store<I18n['_']>;
  $isReady: Store<boolean>;
  translated: Translated;
};

const identity = ((key: string) => key) as I18n['_'];

export function createLinguiIntegration({
  instance,
  setup,
  teardown,
}: {
  instance: I18n | Store<I18n | null>;
  setup: Event<void>;
  teardown?: Event<void>;
}): LinguiIntegration {
  const initialized = createEvent<I18n>();
  const contextChanged = createEvent();
  const destroy = teardown ?? createEvent();

  const $instance: Store<I18n | null> = is.store(instance)
    ? instance
    : createStore(instance as I18n | null, {
        serialize: 'ignore',
        name: '$instance',
      });

  const $unsubscribe = createStore<(() => void) | null>(null, {
    serialize: 'ignore',
    name: '$contextChangeListener',
  });

  // -- Internal API

  const $derivedT = combine($instance, (i18n): I18n['_'] | null =>
    i18n ? i18n.t.bind(i18n) : null,
  );

  const $stanaloneT = createStore<I18n['_'] | null>(null, {
    serialize: 'ignore',
    name: '$stanaloneT',
  });

  // -- Public API
  const $isReady = createStore(false, { serialize: 'ignore' });

  const $t = combine(
    { derived: $derivedT, standalone: $stanaloneT },
    ({ derived, standalone }): I18n['_'] => standalone ?? derived ?? identity,
  );

  $isReady.on(initialized, () => true).on(destroy, () => false);

  function translated(descriptor: MessageDescriptor): Store<string> {
    return combine({ t: $t }, ({ t }) => t(descriptor));
  }

  sample({
    clock: [initialized, sample({ clock: contextChanged, source: $instance, filter: Boolean })],
    fn: (i18n) => i18n.t.bind(i18n),
    target: $stanaloneT,
  });

  const setupListenersFx = createEffect((i18n: I18n) => {
    const boundContextChanged = scopeBind(contextChanged, { safe: true });
    const contextChangeListener = () => boundContextChanged();
    const unsubscribe = i18n.on('change', contextChangeListener);

    return { unsubscribe };
  });

  const destroyListenersFx = attach({
    source: {
      unsubscribe: $unsubscribe,
      i18n: $instance,
    },
    effect: ({ unsubscribe, i18n }) => {
      if (!i18n || !unsubscribe) return;
      unsubscribe();
    },
  });

  sample({
    clock: [setup, $instance.updates],
    source: $instance,
    filter: Boolean,
    target: [initialized, setupListenersFx],
  });

  sample({
    clock: setupListenersFx.doneData,
    fn: ({ unsubscribe }) => unsubscribe,
    target: $unsubscribe,
  });

  sample({
    clock: destroy,
    target: destroyListenersFx,
  });

  sample({
    clock: destroyListenersFx.done,
    target: [$unsubscribe.reinit],
  });

  return {
    $t,
    $isReady,
    translated: (firstArg) => {
      return translated(firstArg);
    },
  };
}
