import { cx } from '@linaria/core';
import { PinInput, Text } from '@solid-kit/ui/v2';
import { Index } from 'solid-js';

import { input, inputFailed, inputSuccess } from './index.css';

type ValidationNumbersProps = {
  title: string;
  value?: string[];
  onValueComplete: (clearValue: () => void) => void;
  onValueChange: (details: { value: string[]; valueAsString: string }) => void;
  pinInputSuccess?: boolean;
  pinInputFailed: string | boolean | null;
  invalid?: boolean;
  disabled?: boolean;
};

export const ValidationNumbers = (props: ValidationNumbersProps) => {
  let clearValue = () => {};

  return (
    <PinInput.Root
      autoFocus
      value={props.value}
      onValueComplete={() => props.onValueComplete(clearValue)}
      onValueChange={props.onValueChange}
      disabled={props.disabled}
      invalid={props.invalid}
    >
      {(api) => {
        clearValue = () => api().clearValue();

        return (
          <>
            <PinInput.Label>
              <Text variant="body-1">{props.title}</Text>
            </PinInput.Label>
            <PinInput.Control>
              <Index each={[0, 1, 2, 3]}>
                {(id) => (
                  <PinInput.Input
                    tabindex={1}
                    class={cx(
                      input,
                      props.pinInputSuccess && inputSuccess,
                      props.pinInputFailed && inputFailed,
                    )}
                    index={id()}
                  />
                )}
              </Index>
            </PinInput.Control>
          </>
        );
      }}
    </PinInput.Root>
  );
};
