import { css } from '@linaria/core';

export const table = css`
  position: relative;
  display: grid;
  gap: 1rem;
  overflow: auto;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4;
`;
