import { ErrorBoundary } from 'solid-js';

import '@app/ui/index.css';

import { withHocs } from '../providers';
import { RoutesContainer } from '../routes';

const ApplicationInner = withHocs(() => <RoutesContainer />);

export function Application() {
  return (
    <ErrorBoundary
      fallback={(error) => {
        console.error(error);
        return null;
      }}
    >
      <ApplicationInner />
    </ErrorBoundary>
  );
}
