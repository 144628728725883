import { JSX } from 'solid-js';
import { spread } from 'solid-js/web';

const tagRe = /<([a-zA-Z0-9]+)>(.*?)<\/\1>|<([a-zA-Z0-9]+)\/>/;
const nlRe = /(?:\r\n|\r|\n)/g;

function formatElements(
  value: string,
  elements: { [key: string]: JSX.Element } = {},
): string | Array<JSX.Element | string> {
  const parts = value.replace(nlRe, '').split(tagRe);

  if (parts.length === 1) return value;

  const tree: Array<JSX.Element | string> = [];

  const before = parts.shift();
  if (before) tree.push(before);

  for (const [index, children, after] of getElements(parts)) {
    const element = typeof index !== 'undefined' ? elements[index] : undefined;

    spread(element as Element, {
      children: formatElements(children, elements),
    });

    tree.push(element);

    if (after) tree.push(after);
  }

  return tree;
}

function getElements(parts: string[]): Array<readonly [string, string, string]> {
  if (!parts.length) return [];

  const [paired, children, unpaired, after] = parts.slice(0, 4);

  const triple = [paired || unpaired, children || '', after] as const;
  return [triple].concat(getElements(parts.slice(4, parts.length)));
}

export { formatElements };
