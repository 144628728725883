import { ParentProps } from 'solid-js';

import { layoutModel } from '../../model';
import { Root } from './index.css';

type Props = ParentProps<{
  withoutSidebar?: boolean;
  withRightBar?: boolean;
  class?: string;
}>;

export const PageContainer = (props: Props) => {
  const layout = layoutModel.useModel();

  return (
    <Root
      layoutIsCollapsed={layout.sidebarIsCollapsed()}
      withRightBar={props.withRightBar}
      class={props.class}
    >
      {props.children}
    </Root>
  );
};
