import { Provider } from 'effector-solid';
import { type Component } from 'solid-js';

import { scope } from '@shared/lib/composer';

export const withEffector = (WrappedComponent: Component) => (props) => {
  return (
    <Provider value={scope}>
      <WrappedComponent {...props} />
    </Provider>
  );
};
