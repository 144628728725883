import { css } from '@linaria/core';
import { styled } from '@solidex/linaria';

export const Root = styled.div<{
  padding: string;
}>`
  --layout-root-padding: ${({ padding }) => padding + 'rem' ?? '1rem'};

  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;

export const header = css`
  position: static;
  z-index: 100;
  width: 100%;
  height: var(--header-height);
  transition: transform 0.3s ease;
  transform: translateY(0);

  &[fixed] {
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s ease;
    transform: translateY(0);
  }

  &[fullscreen] {
    opacity: 0;
    transition:
      transform 0.3s ease,
      opacity 0s 0.3s;
    transform: translateY(calc(-100% - 5px));
  }
`;

export const main = css`
  position: relative;
  display: flex;
  flex: 1;
  margin-top: var(--header-height);
  margin-left: var(--sider-width);
  transition: margin-left 200ms ease;

  &[collapsed] {
    margin-left: 0;
  }

  &[fullscreen] {
    margin-top: 0;
  }
`;

export const sider = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  width: var(--sider-width);
  transition: width 200ms ease;

  &[collapsed] {
    width: 0;
  }
`;

export const content = css`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
