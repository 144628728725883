import { type AxiosRequestConfig } from 'axios';

import { env } from '@shared/config/env';
import { fetcherService } from '@shared/services/fetcher';

export const requestFx = async <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  return fetcherService.inputs.requestFx({
    ...config,
    ...options,
    baseURL: `${env.PLATFORM_ID_FRONTEND_API_URL}`,
  });
};
