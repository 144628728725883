import { msg } from '@lingui/macro';
import { trackNetworkStatus, trackPageVisibility } from '@withease/web-api';
import { createEvent, createStore, sample } from 'effector';

import { composerModel } from '@shared/lib/composer';
import { localeService } from '@shared/services/locale';
import { notificationModel } from '@shared/services/notification';

const { appInited, appStopped } = composerModel.outputs;

const viewerServiceStarted = createEvent();
const viewerServiceStopped = createEvent();

const $appInited = createStore(false)
  .on(appInited, () => true)
  .on(appStopped, () => false);

const $appNotInited = $appInited.map((isInit) => !isInit);

const networkStatus = trackNetworkStatus({
  setup: viewerServiceStarted,
  teardown: viewerServiceStopped,
});

const pageVisibility = trackPageVisibility({
  setup: viewerServiceStarted,
  teardown: viewerServiceStopped,
});

const $isPageVisible = pageVisibility.$visible;
const $isPageHidden = pageVisibility.$hidden;
const pageBecomesVisible = pageVisibility.visible;
const pageBecomesHidden = pageVisibility.hidden;

sample({
  clock: networkStatus.offline,
  source: { message: localeService.outputs.translated(msg`Подключитесь к сети`) },
  target: notificationModel.inputs.showErrorFx,
});

export const viewerModel = {
  inputs: {
    viewerServiceStarted,
    viewerServiceStopped,
  },
  outputs: {
    appStopped,
    appInited,
    $appInited,
    $appNotInited,
    $isPageVisible,
    $isPageHidden,
    pageBecomesVisible,
    pageBecomesHidden,
  },
};
