import { Environment } from '@solid-kit/ui/v2';
import { type Component } from 'solid-js';

export const withUIEnvironment = (WrappedComponent: Component) => (props) => {
  return (
    <Environment>
      <WrappedComponent {...props} />
    </Environment>
  );
};
