import * as Yup from 'yup';

export const initValidationLocale = () => {
  Yup.setLocale({
    string: {
      min: (num) => `Минимум ${num.min} символов`,
      email: 'Эл.почта указана неверно',
    },
    mixed: {
      required: 'Это обязательное поле',
    },
  });
};
