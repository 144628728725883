import { cx } from '@linaria/core';
import { ic_m_menu } from '@rteam-fe/icons';
import { Icon } from '@solid-kit/ui';
import { Button } from '@solid-kit/ui/v2';

import { env } from '@shared/config/env';
import { layoutModel } from '@shared/services/layout';
import { Link } from '@shared/services/router';

import * as styles from './index.css';
import { RootStyled } from './index.css';
import { PlatformLogo } from './logo';

export const AppHeader = () => {
  const layout = layoutModel.useModel();

  return (
    <RootStyled>
      <div class={cx(styles.block, styles.leftBlock)}>
        <div class={styles.toggler}>
          <Button
            variant="text"
            rightIcon={<Icon source={ic_m_menu} />}
            onClick={() => {
              layout.sidebarIsOpened() ? layout.sidebarClosed() : layout.sidebarOpened();
            }}
          />
        </div>
        <Link to={`${env.PLATFORM_FRONTEND_URL}`}>
          <PlatformLogo />
        </Link>
      </div>
    </RootStyled>
  );
};
