import { createRule } from '@shared/lib/effector-form';
import { Yup } from '@shared/lib/validation';

export const codeVerifyFormConfig = {
  codeId: {
    init: '',
    rules: [
      createRule({
        name: 'codeId',
        schema: Yup.string().required(),
      }),
    ],
  },
  codeValue: {
    init: '',
    rules: [
      createRule({
        name: 'codeValue',
        schema: Yup.string().required('Заполните поле').max(4),
      }),
    ],
  },
};
