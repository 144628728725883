import { attach, createEvent, createStore, sample, split } from 'effector';
import type { Effect, Event, EventCallable, Store } from 'effector';
import { combineEvents, not } from 'patronum';

import { Mutex } from './lib';

type Performer = { start: EventCallable<void>; end: Event<any> };

type NormalPerformer = {
  start: EventCallable<void> | Effect<void, any, any>;
  end: Event<void>;
  $pending: Store<boolean>;
};

export function createBarrier({
  active,
  activateOn,
}: {
  active?: Store<boolean>;
  activateOn?: {
    started?: ({ params }: { params?: any }) => boolean;
    failure?: ({ params, error }: { params?: any; error?: any }) => boolean;
  };
}) {
  const $mutex = createStore<Mutex | null>(new Mutex(), { serialize: 'ignore' });

  const activated = createEvent();
  const deactivated = createEvent();
  const touch = createEvent();
  const requestStarted = createEvent<{ params: unknown; error: unknown }>();
  const requestFailed = createEvent<{ params: unknown; error: unknown }>();

  sample({
    clock: activated,
    target: attach({
      source: $mutex,
      async effect(mutex) {
        console.warn('Barrier activated');
        await mutex?.acquire();
      },
    }),
  });

  sample({
    clock: deactivated,
    target: attach({
      source: $mutex,
      effect(mutex) {
        console.warn('Barrier deactivated');
        mutex?.release();
      },
    }),
  });

  let $active;

  // Overload: active
  if (active) {
    $active = active;
  } else if (activateOn && 'started' in activateOn && activateOn.started) {
    $active = createStore(false);
    const callback = activateOn.started;

    sample({
      clock: requestStarted,
      filter: ({ params }) => callback({ params }),
      fn: () => true,
      target: [$active, touch],
    });
  } else if (activateOn && 'failure' in activateOn && activateOn.failure) {
    $active = createStore(false);
    const callback = activateOn.failure;

    sample({
      clock: requestFailed,
      filter: ({ params, error }) => callback({ params, error }),
      fn: () => true,
      target: [$active, touch],
    });
  } else {
    throw new Error('Invalid configuration of createBarrier');
  }

  split({
    clock: [$active, touch],
    source: $active,
    match: { activated: Boolean },
    cases: { activated, __: deactivated },
  });

  return {
    $active,
    activated,
    deactivated,
    __: { $mutex, touch, requestStarted, requestFailed },
  };
}

export function applyBarrier(
  barrier: ReturnType<typeof createBarrier>,
  config: {
    active?: Store<boolean>;
    activateOn?: Event<void>;
    deactivateOn?: Event<void>;
    perform?: Performer[];
    failure?: (options: { error: unknown }) => boolean;
  },
) {
  const performers = (config.perform || []).map((performer) => {
    const $pending = createStore(false, { serialize: 'ignore' })
      .on(performer.start, () => true)
      .on(performer.end, () => false);
    return { ...performer, $pending };
  });

  sample({
    clock: barrier.__.touch,
    filter: barrier.$active,
    target: startOnlyNotPending(performers),
  });

  sample({
    clock: combineEvents({
      events: performers.map((i) => i.end),
    }),
    fn: () => false,
    target: barrier.$active,
  });

  function startOnlyNotPending(performers: NormalPerformer[]): EventCallable<void> {
    const clock = createEvent();

    for (const { start, $pending } of performers) {
      // @ts-expect-error 😇😇😇
      sample({
        clock,
        filter: not($pending),
        target: start,
      });
    }

    return clock;
  }
}
