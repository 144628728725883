import { css } from '@linaria/core';

export const header = css`
  width: 100%;
  height: var(--header-height);
  background-color: rgb(255 255 255 / 90%);
  box-shadow: 0 1px 2px rgb(0 0 0 / 9%);
  backdrop-filter: blur(51px);

  &[fixed] {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    transition: transform 0.3s ease;
    transform: translateY(0);
  }
`;

export const main = css`
  position: relative;
  display: grid;
  flex: 1;
  grid-template-columns: auto 1fr;
  margin-top: var(--header-height);
`;

export const sider = css`
  z-index: 1;
  width: var(--sider-width);
  transition: width 200ms ease;

  &[collapsed] {
    width: 0;
  }
`;

export const content = css`
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100vh - var(--header-height));
`;

export const root = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
`;
