import { Fragment } from '@solid-kit/ui';
import { useUnit } from 'effector-solid';
import { type Component, createMemo, For, Show } from 'solid-js';

import { codeVerifyService } from '@services/profile/code-verify';
import { accountDeleteService } from '@services/profile/delete-account';

import { Modal, modalService } from '@shared/services/modals';

const { CodeVerifyModal } = codeVerifyService.containers;
const { DeleteAccountModal } = accountDeleteService.containers;

export const withModal = (WrappedComponent: Component) => (props) => {
  return (
    <Fragment>
      <WrappedComponent {...props} />
      <ModalContainer />
    </Fragment>
  );
};

function ModalContainer() {
  const { activeModals } = useUnit({
    activeModals: modalService.outputs.$activeModals,
  });

  return <For each={activeModals()} children={(modal) => <AppModal modal={modal} />} />;
}

function AppModal(props: { modal: Modal }) {
  const { isModalActive } = useUnit({
    isModalActive: modalService.outputs.$isModalActive,
  });

  const Component = createMemo(() => {
    if (props.modal.name === 'code-verify') {
      return <CodeVerifyModal {...props.modal} />;
    } else if (props.modal.name === 'delete-account') {
      return <DeleteAccountModal {...props.modal} />;
    } else {
      return null;
    }
  });

  return (
    <Show when={isModalActive()}>
      <Component />
    </Show>
  );
}
