import { debug } from 'patronum';

import { scope } from '@shared/lib/composer';

import { appService } from './app';

// -- https://patronum.effector.dev/methods/debug/#scope-registration
// eslint-disable-next-line effector/no-patronum-debug
debug.registerScope(scope, { name: 'app_scope' });
window.addEventListener('DOMContentLoaded', () => {
  appService.inputs.start();
});
