import { content, header, main, root, sider } from './base-layout.css';

export function BaseLayout(props) {
  return <div class={root}>{props.children}</div>;
}

BaseLayout.Header = (props) => {
  return (
    <div class={header} attr:fixed={props.fixed ? '' : null}>
      {props.children}
    </div>
  );
};

BaseLayout.Sider = (props) => {
  return (
    <div class={sider} attr:collapsed={props.collapsed ? '' : null}>
      {props.children}
    </div>
  );
};

BaseLayout.Main = (props) => {
  return <div class={main}>{props.children}</div>;
};

BaseLayout.Content = (props) => {
  return <div class={content}>{props.children}</div>;
};
