import { registerPlatformService } from '@shared/lib/composer';

import { viewerModel } from './model';

export const viewerService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: viewerModel.inputs.viewerServiceStarted,
    stop: viewerModel.inputs.viewerServiceStopped,
  },
  api: {
    outputs: {
      ...viewerModel.outputs,
    },
  },
});
