import { createEvent } from 'effector';

import { registerPlatformService } from '@shared/lib/composer';

import { addError, codeVerifyForm, completed, finished } from './model';
import { CodeVerifyModal } from './ui';

export const codeVerifyService = registerPlatformService({
  importMetaUrl: import.meta.url,
  lifecycle: {
    start: createEvent(),
  },
  api: {
    inputs: {
      addError,
      finished,
    },
    outputs: {
      completed,
      $formValues: codeVerifyForm.$values,
    },
    forms: {
      codeVerifyForm,
    },
    containers: {
      CodeVerifyModal,
    },
  },
});
