import { msg, Trans } from '@lingui/macro';
import { ic_m_clear, ic_m_eye, ic_m_eye_off } from '@rteam-fe/icons';
import { DataCell, Icon, Input, Stack, Tooltip } from '@solid-kit/ui';
import { Button, Dialog, Text } from '@solid-kit/ui/v2';
import { useUnit } from 'effector-solid';
import { createSignal, onCleanup, onMount, Show } from 'solid-js';

import { useForm } from '@shared/lib/effector-form';
import { useLingui } from '@shared/lib/lingui-solid';
import { modalService } from '@shared/services/modals';

import * as deleteAccountModel from '../../model';
import { modalClosed } from '../../model';
import type { DeleteAccountModal } from '../../types';
import { styles } from './index.css';

export function DeleteAccountModal(props: DeleteAccountModal) {
  const form = useForm(deleteAccountModel.$$deleteAccountForm);
  const { _ } = useLingui();
  const [showPassword, setShowPassword] = createSignal<boolean>(false);
  const toggle = () => setShowPassword(!showPassword());

  const { closeModal, unmounted } = useUnit({
    unmounted: modalClosed,
    closeModal: modalService.inputs.closeModal,
  });

  onCleanup(() => {
    unmounted();
  });

  const handleSubmit = (event: SubmitEvent) => {
    event.preventDefault();
    form.submit();
  };

  onMount(() => {
    if (props.isSocialAuth) {
      form.fields.isSocialAuth.set(props.isSocialAuth);
    }
  });

  return (
    <Dialog.Root
      open={true}
      scrollStrategy="inner"
      centered
      onExitComplete={() => {
        closeModal();
      }}
    >
      {(api) => (
        <Dialog.Portal>
          <Dialog.Backdrop />
          <Dialog.Positioner>
            <Dialog.Content class={styles.content}>
              <Dialog.Header class={styles.header}>
                <Text variant="header-2">
                  <Trans>Удаление аккаунта</Trans>
                </Text>
                <Button
                  class={styles.closeButton}
                  leftIcon={<Icon source={ic_m_clear} />}
                  variant="text"
                  pin="circle-circle"
                  onClick={() => api().setOpen(false)}
                />
              </Dialog.Header>
              <Dialog.Body class={styles.body}>
                <Stack direction="column">
                  <Text variant="header-2">
                    <Show
                      when={!props.isSocialAuth}
                      fallback={<Trans>Вы уверенны что хотите удалить аккаунт?</Trans>}
                    >
                      <Trans>Для подтверждения введите пароль</Trans>
                    </Show>
                  </Text>
                  <Show
                    when={!props.isSocialAuth}
                    fallback={
                      <form method="post" action={`${window.location.origin}/user/social-delete/`}>
                        <Stack spacing={0.5} justify="flex-end">
                          <Button type="submit" color="secondary">
                            <Trans>Удалить</Trans>
                          </Button>
                        </Stack>
                      </form>
                    }
                  >
                    <DataCell
                      as="div"
                      onSubmit={handleSubmit}
                      content={
                        <Input
                          name="password"
                          placeholder={_(msg`Введите пароль`)}
                          type={showPassword() ? 'text' : 'password'}
                          validity={form.fields.password.hasError() ? 'error' : 'none'}
                          value={form.fields.password.value}
                          whenChange={form.fields.password.onChange}
                          labelOutside
                          autocapitalize="none"
                          autocomplete="current-password"
                          maxLength={100}
                          rightContent={
                            <Tooltip
                              title={
                                showPassword() ? _(msg`Скрыть пароль`) : _(msg`Показать пароль`)
                              }
                            >
                              <Icon
                                source={showPassword() ? ic_m_eye_off : ic_m_eye}
                                onClick={toggle}
                                size={20}
                              />
                            </Tooltip>
                          }
                        />
                      }
                      rightContent={
                        <Stack spacing={0.5}>
                          <Button onClick={() => form.submit()} type="submit" color="secondary">
                            <Trans>Удалить</Trans>
                          </Button>
                        </Stack>
                      }
                    />
                  </Show>
                </Stack>
              </Dialog.Body>
            </Dialog.Content>
          </Dialog.Positioner>
        </Dialog.Portal>
      )}
    </Dialog.Root>
  );
}
