import { i18n } from '@lingui/core';
import { detect, fromNavigator } from '@lingui/detect-locale';
import { attach, createEvent, createStore, sample } from 'effector';
import { type Finally, persist } from 'effector-storage/local';

import { APP_SETTINGS } from '@shared/config';
import { createLinguiIntegration } from '@shared/lib/web-api';

import { AppLanguage } from '../languages';
import { sanitizeAppLanguageSetting } from '../lib/helpers';

const STORAGE_KEY = 'appLanguage';

const start = createEvent();
const stop = createEvent();
const setup = createEvent();
const synchronizedAppLanguage = createEvent<Finally<AppLanguage, unknown>>();

const setAppLanguage = createEvent<AppLanguage>();
const $appLanguage = createStore(
  sanitizeAppLanguageSetting(detect(fromNavigator()) || APP_SETTINGS.DEFAULT_LANGUAGE),
);

const { $t, $isReady, translated } = createLinguiIntegration({
  instance: i18n,
  setup: setup,
  teardown: stop,
});

persist({
  pickup: start,
  store: $appLanguage,
  key: STORAGE_KEY,
  finally: synchronizedAppLanguage,
});

sample({
  clock: setAppLanguage,
  target: $appLanguage,
});

const initLocaleLanguageFx = attach({
  source: {
    language: $appLanguage.map((lang) => sanitizeAppLanguageSetting(lang)),
  },
  effect: async ({ language }) => {
    document.documentElement.lang = language;

    let mod: any;
    switch (language) {
      case AppLanguage.en: {
        mod = await import(`../locales/en/messages`);
        break;
      }
      default: {
        mod = await import(`../locales/ru/messages`);
        break;
      }
    }

    i18n.load(language, mod.messages);
    i18n.activate(language);
  },
});

sample({
  clock: synchronizedAppLanguage,
  target: initLocaleLanguageFx,
});

sample({
  clock: initLocaleLanguageFx.done,
  target: setup,
});

export const localeModel = {
  inputs: {
    start,
    stop,
    setAppLanguage,
  },
  outputs: {
    isReady: $isReady,
    $t,
    translated,
    $appLanguage,
  },
};
