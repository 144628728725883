import type { Effect, EventCallable, Store } from 'effector';

export type Navigate = {
  pathname?: string | null;
  search?: string | null;
  type?: 'push' | 'reload' | 'replace' | 'openTab';
};

export type RouteParams = Record<string, string>;
export type RouteQuery = Record<string, string>;
export type RouteParamsAndQuery<Params extends RouteParams> = {
  params: Params;
  query?: RouteQuery;
};
export interface NavigateParams<Params extends RouteParams> extends RouteParamsAndQuery<Params> {
  replace?: boolean;
}

export type Recalculated = {
  params: RouteParams;
  query: RouteQuery;
  matches: RouteMatch[];
};

export type RouteInstance<Params extends RouteParams> = {
  $isOpened: Store<boolean>;
  $params: Store<Params>;
  $query: Store<RouteQuery>;
  opened: EventCallable<RouteParamsAndQuery<Params>>;
  updated: EventCallable<RouteParamsAndQuery<Params>>;
  navigated: EventCallable<RouteParamsAndQuery<Params>>;
  closed: EventCallable<void>;
  navigate: Effect<NavigateParams<Params>, NavigateParams<Params>>;
  open: Effect<Params extends EmptyObject ? void : Params, RouteParamsAndQuery<Params>>;
  kind: typeof Kind.ROUTE;
};

export interface RouteInstanceInternal<Params extends RouteParams> extends RouteInstance<Params> {
  opened: EventCallable<RouteParamsAndQuery<Params>>;
  updated: EventCallable<RouteParamsAndQuery<Params>>;
  closed: EventCallable<void>;
}

export type RouteObject<Params extends RouteParams> = {
  route: RouteInstance<Params>;
  path: string;
  parent?: RouteInstance<Params>;
};
export type EmptyObject = { [key in string]: never };

export type Pattern<RouteParams> = Readonly<[RegExp, (...parts: string[]) => RouteParams]>;
export type RouterConfig = Record<string, Pattern<any> | string>;
export interface RouterOptions {
  links?: boolean;
  search?: boolean;
}

export type MatchFilter = string[] | RegExp | ((s: string) => boolean);

export interface PathMatch {
  params: RouteParams;
  path: string;
}

export type PathParams<P extends string | readonly string[]> =
  P extends `${infer Head}/${infer Tail}`
    ? [...PathParams<Head>, ...PathParams<Tail>]
    : P extends `:${infer S}?`
      ? [S]
      : P extends `:${infer S}`
        ? [S]
        : P extends `*${infer S}`
          ? [S]
          : [];

export type MatchFilters<P extends string | readonly string[] = any> = P extends string
  ? { [K in PathParams<P>[number]]?: MatchFilter }
  : Record<string, MatchFilter>;

type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ''
    ? []
    : S extends `${infer T}${D}${infer U}`
      ? [T, ...Split<U, D>]
      : [S];

type PathToParams<PathArray, Params = {}> = PathArray extends [infer First, ...infer Rest]
  ? First extends `:${infer Param}`
    ? // eslint-disable-next-line @typescript-eslint/no-shadow
      First extends `:${infer Param}?`
      ? PathToParams<Rest, Params & Partial<Record<Param, string>>>
      : PathToParams<Rest, Params & Record<Param, string>>
    : PathToParams<Rest, Params>
  : Params;

type ParseUrl<Path extends string> = PathToParams<Split<Path, '/'>>;

type ParamsFromConfig<K extends RouterConfig> = {
  [key in keyof K]: K[key] extends Pattern<infer P>
    ? P
    : K[key] extends string
      ? ParseUrl<K[key]>
      : never;
};

type MappedC<A, B> = {
  [K in keyof A & keyof B]: A[K] extends B[K] ? never : K;
};

type OptionalKeys<T> = MappedC<T, Required<T>>[keyof T];

export type ParamsArg<
  Config extends RouterConfig,
  PageName extends keyof Config,
> = keyof ParamsFromConfig<Config>[PageName] extends never
  ? []
  : keyof ParamsFromConfig<Config>[PageName] extends OptionalKeys<
        ParamsFromConfig<Config>[PageName]
      >
    ? [ParamsFromConfig<Config>[PageName]?]
    : [ParamsFromConfig<Config>[PageName]];

export interface RouteMatch extends PathMatch {
  route: Route;
}

export type RouteDefinition<S extends string | string[] = any> = {
  path: S;
  matchFilters?: MatchFilters<S>;
  children?: RouteDefinition | RouteDefinition[];
  route: RouteInstance<any>;
};

export interface Route {
  originalPath: string;
  pattern: string;
  matcher: (location: string) => PathMatch | null;
  matchFilters?: MatchFilters;
  route: RouteInstance<any>;
}

export interface Branch {
  routes: Route[];
  score: number;
  matcher: (location: string) => RouteMatch[] | null;
}

export interface NavigateProps<Params extends RouteParams> {
  to: RouteInstance<Params>;
  params?: Params;
  query?: RouteQuery;
}

export const Kind = {
  ROUTE: Symbol(),
};
