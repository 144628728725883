import type { MeShowResponse } from '@shared/api/id-frontend-api';

import { ProfileGender } from '../consts';
import { type Profile } from '../model/types';

export interface OutputSchema extends Profile {}

export function getOutput(output: MeShowResponse): OutputSchema {
  return {
    id: output.id!,
    confirmed: output.confirmed!,
    duoAuth: output.duoAuth!,
    firstName: output.firstName!,
    lastName: output.lastName ?? null,
    middleName: output.middleName ?? null,
    phone: output.phone!,
    email: output.email ?? null,
    emailVerified: output.emailVerified ?? null,
    gender: output.gender! as ProfileGender,
    birthday: output.birthday ?? null,
    createdAt: output.createdAt ?? null,
    isSocialAuth: output.isSocialAuth ?? false,
  };
}

export function toKnownErr(e: any) {
  console.error(e);
  return Promise.reject(e);
}
