export const screenSizes = {
  base: 0,
  sm: 700,
  md: 1024,
  lg: 1360,
  xl: 1600,
  xxl: 1920,
  xxxl: 2240,
};

export function createBreakPointsFromSizes<T extends Record<string, number>>(sizes: T) {
  return Object.entries(sizes).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: `(min-width: ${value}px)` }),
    {} as Record<keyof T, string>,
  );
}
